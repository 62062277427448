<page>
  <div style="padding-top: 16px">

    <div class="flex flex-column gap-3">
      <div>

        <p-fieldset legend="BASIC PROFILE">
          <user-bare-minimal-profile></user-bare-minimal-profile>
        </p-fieldset>


      </div>
      <div>


        <home-bookmark-profile></home-bookmark-profile>



      </div>
     
      <div>



        <home-members-who-viewed-contact-details></home-members-who-viewed-contact-details>



      </div>
      <div>




        <home-contact-details-viewed-by-me></home-contact-details-viewed-by-me>




      </div>
      <div>




        <home-recent-visitor-to-my-profile></home-recent-visitor-to-my-profile>



      </div>
      <div>


        
        <home-my-recent-visits></home-my-recent-visits>
        

      </div>

      <div>


        <p-fieldset legend="SAVED SEARCHES">
          <home-saved-searches></home-saved-searches>
        </p-fieldset>


      </div>

    </div>














  </div>
</page>