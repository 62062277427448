<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<div class="demo-tab-content" *ngIf="!showLoader">
  <div class="flex py-1">
    <div class="col-12 table-container">
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">Name oF Firm</div>
        <div class="col-10">
          {{ ui?.candidate_Name_Firm || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">Working Country</div>
        <div class="col-10">
          {{ ui?.countryName || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">Working City</div>
        <div class="col-10">
          {{ ui?.cityName || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">When Is The Next Leave</div>
        <div class="col-10">
          {{ ui?.candidate_NextLeave || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">Visa Status/ Family Visa etc</div>
        <div class="col-10">
          {{ ui?.candidate_visaStatus || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">Education Category</div>
        <div class="col-10">{{ ui?.educationCategory || "--" }}</div>
      </div>
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">
          Fathers Native Place (Family Roots At)
        </div>
        <div class="col-10">
          {{ ui?.Father_NativeDitrict || "--" }}
        </div>
      </div>
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">Job Catagory</div>
        <div class="col-10">{{ ui?.jobCategory || "--" }}</div>
      </div>
      <div class="flex py-1">
        <div class="col-2 text-left font-semibold">Parents Settled at</div>
        <div class="col-10">{{ ui?.parentssettledat || "--" }}</div>
      </div>
    </div>
  </div>
</div>
