import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { MemberShipInformationModel } from "src/app/dto/MemberShipInformationModel";

@Component({
  selector: 'user-bare-minimal-profile',
  templateUrl: './user-bare-minimal-profile.component.html',
  styleUrls: ['./user-bare-minimal-profile.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class UserBareMinimalProfileComponent implements OnInit {
  userInformation: MemberShipInformationModel;
  userloggedIn: string;
  showLargeImage = false;
  userId: string;
  imageToDisplay: HostWindowModel;
  showLoader = true;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, private confirmationService: ConfirmationService) {

  }


  ngOnInit() {
    const self = this;
    // self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
      // self.userloggedIn = result.UserLoggedIn;
      self.userInformation = {} as MemberShipInformationModel;
      self.imageToDisplay = {} as HostWindowModel;
      self.commonService.getHomeUserProfile(self.userloggedIn).subscribe(
        data => {
          self.userInformation = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    // });

  }

  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (itemId != null) {
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error =>{
          
        }
      );
    }
    else {
      
    }
  }

}
