import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { SearchQueryBody } from './advanced.component.domain';
import {
  RadioButtonSource,
  KeyValuePair,
  QuickSearchResult,
  SearchResult
} from '../quicks/quicks.component.domain';
import { Dropdown } from "../../../dto/Dropdown";
import { ExpressInterestModel } from "../../../dto/ExpressInterestModel";
import { ExpressInterestSendModel } from "../../../dto/ExpressInterestSendModel";
import { SendMessageModelCsKind } from "../../../dto/SendMessageModelCsKind";
import { SendMessageGeneric } from "../../../dto/SendMessageGeneric";
import { ExpressInterestCarrier } from "../../../dto/ExpressInterestCarrier";
import { AddBookmarkModel } from "../../../dto/AddBookmarkModel";
import { Commonservice } from '../../../common/reusable/servicecenter/commonservice.component';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { dropdown } from './../../../common/util/validators/commonvalidator';
import { ActionResponse } from 'src/app/dto/ActionResponse';
import { Router } from '@angular/router';



@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class AdvancedSearchComponent implements OnInit {
  // elements for height
  heightToSelected: Dropdown;
  heightFromSelected: Dropdown;
  imageLoader: boolean = false;
  maritalStatusSelected: Dropdown;
  physicalStatusSelected: Dropdown;
  fathersPlaceSelected: Dropdown;
  parentsSettledSelected: Dropdown;
  workplaceSelected: Dropdown;
  religionSelected: Dropdown;
  qualificationSelected: Dropdown;
  jobCategorySelected: Dropdown;
  initialPage: Object;
  heightArrayFrom: Array<Dropdown>;
  heightArrayTo: Array<Dropdown>;
  ageFromSelected: Dropdown;
  ageToSelected: Dropdown;
  ageArrayFrom: Array<Dropdown>;
  ageArrayTo: Array<Dropdown>;
  maritalStatus: Array<Dropdown>;
  physicalStatus: Array<Dropdown>;
  fathersPlace: Array<Dropdown>;
  parentsSettled: Array<Dropdown>;
  workplaceList: Array<Dropdown>;
  religionList: Array<Dropdown>;
  qualificationList: Array<Dropdown>;
  jobCategoryList: Array<Dropdown>;
  complexionList: Array<Dropdown>;
  groomOrBride: RadioButtonSource;
  sortBySource: RadioButtonSource;
  nativePlaceSelectedValue: Dropdown;
  nativePlace: Array<Dropdown>;
  communitySelectedValue: Dropdown;
  complexionSelected: Dropdown;
  community: Array<Dropdown>;
  lookingForSelectedValue: Dropdown;
  lookingFor: Array<Dropdown>;
  searchQuery: SearchQueryBody;
  searchResult: Array<SearchResult> = [];
  masterData: Array<SearchResult> = [];
  showSearchBox: boolean;
  profileWithImage: boolean;
  saveSearch: boolean;
  searchName: string = "";
  public textWrapSettings;
  confirmationTitle: string = "Confirmation";
  userloggedIn: string;
  expressInterestModel: ExpressInterestSendModel;
  mtd: ExpressInterestModel;
  showLargeImage = false;
  imageToDisplay: HostWindowModel;
  userId: string;
  smg: SendMessageGeneric;
  showLoader = false;
  candidatesWorkingNationality: Dropdown[] = [];
  candidatesWorkingNationalitySelectedValue: Dropdown;
  fathersWorkingNationality: Dropdown[] = [];
  fathersWorkingNationalitySelectedValue: Dropdown;

  totalRecords: number = 0;
  page: number = 1;
  size: number = 10;
  first: number = 0;
  paginationCount: number = 0;
  loadFromPresavedSearch: boolean = false;
  showPreSavedLoad: boolean = false;

  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;
  sendMessageText: string;


  constructor(private commonservice: Commonservice, private messageService: MessageService, private confirmationService: ConfirmationService, private datePipe: DatePipe, private localStore: LocalStore, private router: Router,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    const self = this;
    self.loadControls();
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;
      self.loadFromAge();
      self.loadToAge();
      self.loadFromHeight();
      self.loadToHeight();
      self.loadGroomAndBrideSource();
      self.loadSortBy();
      self.loadMaritalStatus();
      self.loadPhysicalStatus();
      self.loadFathersPlace();
      self.loadParentsSettledAtPlace();
      self.loadWorkPlace();
      self.loadReligion();
      self.loadQualification();
      self.loadJobCategory();
      self.loadComplexion();
      self.loadCandidatesWorkingNationality();
      self.loadParentsWorkingNationality();
      let advancedSearch = sessionStorage.getItem("AdvancedSearch");
      if (advancedSearch !== null) {
        self.searchQuery = JSON.parse(advancedSearch);
        self.saveSearch = true;
        self.searchName = self.searchQuery.SearchName;
        self.profileWithImage = self.searchQuery.ShowWithProfile;
        self.showPreSavedLoad = true;
        self.loadFromPresavedSearch = true;
        self.searchFromParams(self.searchQuery);
      }
    });
  }

  loadControls() {
    const self = this;
    self.textWrapSettings = { wrapMode: 'both' };
    // initialize arrays
    self.heightArrayFrom = [] as Array<Dropdown>;
    self.heightArrayTo = [] as Array<Dropdown>;
    self.nativePlace = [] as Array<Dropdown>;
    self.community = [] as Array<Dropdown>;
    self.lookingFor = [] as Array<Dropdown>;
    self.ageArrayFrom = [] as Array<Dropdown>;
    self.ageArrayTo = [] as Array<Dropdown>;
    self.maritalStatus = [] as Array<Dropdown>;
    self.physicalStatus = [] as Array<Dropdown>;
    self.fathersPlace = [] as Array<Dropdown>;
    self.parentsSettled = [] as Array<Dropdown>;
    self.workplaceList = [] as Array<Dropdown>;
    self.religionList = [] as Array<Dropdown>;
    self.qualificationList = [] as Array<Dropdown>;
    self.jobCategoryList = [] as Array<Dropdown>;
    self.complexionList = [] as Array<Dropdown>;
    self.searchResult = [] as Array<SearchResult>;
    // initialize arrays

    // iniitalize the objects
    self.heightFromSelected = {} as Dropdown;
    self.heightToSelected = {} as Dropdown;
    self.nativePlaceSelectedValue = {} as Dropdown;
    self.communitySelectedValue = {} as Dropdown;
    self.lookingForSelectedValue = {} as Dropdown;
    self.ageFromSelected = {} as Dropdown;
    self.ageToSelected = {} as Dropdown;

    self.maritalStatusSelected = {} as Dropdown;
    self.physicalStatusSelected = {} as Dropdown;
    self.fathersPlaceSelected = {} as Dropdown;
    self.parentsSettledSelected = {} as Dropdown;
    self.workplaceSelected = {} as Dropdown;
    self.religionSelected = {} as Dropdown;
    self.qualificationSelected = {} as Dropdown;

    self.searchQuery = {} as SearchQueryBody;
    // iniitalize the objects

    self.groomOrBride = {} as RadioButtonSource;
    self.groomOrBride.source = [] as Array<Dropdown>;

    self.sortBySource = {} as RadioButtonSource;
    self.sortBySource.source = [] as Array<Dropdown>;
    self.showSearchBox = true;
    self.profileWithImage = false;
    self.saveSearch = false;
    self.imageToDisplay = {} as HostWindowModel;



    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
  }

  // this is for load to age
  loadFromAge() {
    const self = this;
    this.commonservice.getAge().subscribe(
      data => {
        self.loadFromAgeAsync(data);
      },
      error => { }
    );
  }
  async loadFromAgeAsync(data) {
    const self = this;

    self.ageArrayFrom = [];
    _.forEach(data, function (value) {
      const ageFrom = {} as Dropdown;
      ageFrom.Value = value.value;

      if (value.name != undefined) {
        ageFrom.Name = value.name.toString();
      }
      self.ageArrayFrom.push(ageFrom);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.ageFromSelected = self.ageArrayFrom[self.ageArrayFrom.findIndex(x => x.Value.toString() === advancedSearch.StartAge)];
    }
  }
  // this is for load to age

  // this is for load to age
  loadToAge() {
    const self = this;
    this.commonservice.getAge().subscribe(
      data => {
        self.loadToAgeAsync(data);
      },
      error => { }
    );
  }
  async loadToAgeAsync(data) {
    const self = this;
    self.ageArrayTo = [];
    _.forEach(data, function (value) {
      const ageTo = {} as Dropdown;
      ageTo.Value = value.value;
      if (value.name != undefined) {
        ageTo.Name = value.name.toString();
      }
      self.ageArrayTo.push(ageTo);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.ageToSelected = self.ageArrayTo[self.ageArrayTo.findIndex(x => x.Value.toString() === advancedSearch.EndAge)];
    }
  }
  // this is for load to age

  // this is for load from height
  loadFromHeight() {
    const self = this;
    this.commonservice.getHeightFromLocal().subscribe(
      data => {
        self.loadFromHeightAsync(data);
      },
      error => { }
    );
  }

  async loadFromHeightAsync(heights) {
    const self = this;
    self.heightArrayFrom = [];
    _.forEach(heights, function (value) {
      const fromHeight = {} as Dropdown;
      fromHeight.Value = value.value;
      if (value.name != undefined) {
        fromHeight.Name = value.name.toString();
      }

      self.heightArrayFrom.push(fromHeight);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.heightFromSelected = self.heightArrayFrom[self.heightArrayFrom.findIndex(x => x.Value.toString() === advancedSearch.StartHeight)];
    }
  }
  // this is for load from height

  // this is for load to height
  loadToHeight() {
    const self = this;
    this.commonservice.getHeightToLocal().subscribe(
      data => {
        self.loadToHeightAsync(data);
      },
      error => { }
    );
  }
  async loadToHeightAsync(heights) {
    const self = this;
    self.heightArrayTo = [];
    _.forEach(heights, function (value) {
      const toHeight = {} as Dropdown;
      toHeight.Value = value.value;

      if (value.name != undefined) {
        toHeight.Name = value.name.toString();
      }
      self.heightArrayTo.push(toHeight);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.heightToSelected = self.heightArrayTo[self.heightArrayTo.findIndex(x => x.Value.toString() === advancedSearch.EndHeight)];
    }
  }
  // this is for load to height

  loadCandidatesWorkingNationality() {
    const self = this;
    self.commonservice.getCountryTypedropdown().subscribe(
      (data: any) => {
        self.candidatesWorkingNationality = self.appendSelectDropdown2(data);
      },
      error => { }
    );
  }

  loadParentsWorkingNationality() {
    const self = this;
    self.commonservice.getCountryTypedropdown().subscribe(
      (data: any) => {
        self.fathersWorkingNationality = self.appendSelectDropdown2(data);
      },
      error => { }
    );
  }

  async loadNativePlaceAsync(data) {
    const self = this;

    self.nativePlace = [];
    _.forEach(data, function (value) {
      const toHeight = {} as Dropdown;
      toHeight.Value = value.value;

      if (value.name != undefined) {
        toHeight.Name = value.name.toString();
      }
      self.nativePlace.push(toHeight);
    });
  }

  loadGroomAndBrideSource() {
    const self = this;
    const kvpMale = {} as Dropdown;
    kvpMale.Name = 'Groom';
    kvpMale.Value = 1;
    const kvpFemale = {} as Dropdown;
    kvpFemale.Name = 'Bride';
    kvpFemale.Value = 2;
    self.groomOrBride.source.push(kvpMale);
    self.groomOrBride.source.push(kvpFemale);
    self.groomOrBride.selectedValue = self.groomOrBride.source[0];
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.groomOrBride.selectedValue = self.groomOrBride.source[self.groomOrBride.source.findIndex(x => x.Value.toString() === advancedSearch.Gender)];
    }
  }

  loadSortBy() {
    const self = this;
    const dateOfRegistration = {} as Dropdown;
    dateOfRegistration.Name = 'Date Of Registration';
    dateOfRegistration.Value = 1;
    const dateOfLogin = {} as Dropdown;
    dateOfLogin.Name = 'Date Of Login';
    dateOfLogin.Value = 2;
    const dateOfLeaveDate = {} as Dropdown;
    dateOfLeaveDate.Name = 'Date Of Leave Date';
    dateOfLeaveDate.Value = 3;
    self.sortBySource.source.push(dateOfRegistration);
    self.sortBySource.source.push(dateOfLogin);
    self.sortBySource.source.push(dateOfLeaveDate);
    self.sortBySource.selectedValue = dateOfRegistration;
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.sortBySource.selectedValue = self.sortBySource.source[self.sortBySource.source.findIndex(x => x.Value.toString() === advancedSearch.SortBy)];
    }
  }

  loadMaritalStatus() {
    const self = this;
    this.commonservice.getMaritalStatus().subscribe(
      data => {
        self.loadMaritalStatusAsync(data);
      },
      error => { }
    );
  }

  async loadMaritalStatusAsync(data) {
    const self = this;
    self.maritalStatus = [] as Array<Dropdown>;
    _.forEach(data, function (value) {
      const maritalStatusSingle = {} as Dropdown;
      maritalStatusSingle.Value = value.value;
      if (value.name != undefined) {
        maritalStatusSingle.Name = value.name.toString();
      }
      self.maritalStatus.push(maritalStatusSingle);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.maritalStatusSelected = self.maritalStatus[self.maritalStatus.findIndex(x => x.Value.toString() === advancedSearch.MaritalStatus)];
    }
  }

  loadPhysicalStatus() {
    const self = this;
    this.commonservice.getPhysicalStatus().subscribe(
      data => {
        self.loadPhysicalStatusAsync(data);
      },
      error => { }
    );
  }

  async loadPhysicalStatusAsync(data) {
    const self = this;
    self.physicalStatus = [] as Array<Dropdown>;
    _.forEach(data, function (value) {
      const physicalStatus = {} as Dropdown;
      physicalStatus.Value = value.value;

      if (value.name != undefined) {
        physicalStatus.Name = value.name.toString();
      }

      self.physicalStatus.push(physicalStatus);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.physicalStatusSelected = self.physicalStatus[self.physicalStatus.findIndex(x => x.Value.toString() === advancedSearch.PhysicalStatus)];
    }
  }

  loadFathersPlace() {
    const self = this;
    this.commonservice.getCountryTypedropdown().subscribe(
      data => {
        self.loadFathersPlaceAsync(data);
      },
      error => { }
    );
  }

  addSelected(incoming: Array<Dropdown>): Array<Dropdown> {
    const selectPlacebo = {} as Dropdown;
    selectPlacebo.Value = 0;
    selectPlacebo.Name = 'Select';
    incoming.unshift(selectPlacebo);
    return incoming;
  }

  async loadFathersPlaceAsync(data: Array<dropdown>) {
    const self = this;
    self.fathersPlace = [] as Array<Dropdown>;
    _.forEach(data, function (value: dropdown) {
      const fathersPlace = {} as Dropdown;
      fathersPlace.Value = value.value;
      if (value.name != undefined) {
        fathersPlace.Name = value.name.toString();
      }

      self.fathersPlace.push(fathersPlace);
    });
    self.fathersPlace = self.appendSelectDropdown2(self.fathersPlace);
    let advancedSearch = self.getSavedSearch();

  }

  loadParentsSettledAtPlace() {
    const self = this;
    this.commonservice.getParentsSettledAt().subscribe(
      data => {
        self.loadParentsSettledAtAsync(data);
      },
      error => { }
    );
  }

  async loadParentsSettledAtAsync(data) {
    const self = this;
    self.parentsSettled = [] as Array<Dropdown>;
    _.forEach(data.native, function (value) {
      const parentsSettled = {} as Dropdown;
      parentsSettled.Value = value.value;

      if (value.name != undefined) {
        parentsSettled.Name = value.name.toString();
      }

      self.parentsSettled.push(parentsSettled);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null) {
      self.parentsSettledSelected = self.parentsSettled[self.parentsSettled.findIndex(x => x.Value.toString() === advancedSearch.ParentsSettled)];
    }
  }

  loadWorkPlace() {
    const self = this;
    this.commonservice.getCountryTypedropdown().subscribe(
      data => {
        self.loadWorkPlaceAsync(data);
      },
      error => { }
    );
  }

  async loadWorkPlaceAsync(data) {
    const self = this;
    self.workplaceList = [] as Array<Dropdown>;
    self.workplaceList = self.workplaceList;
    _.forEach(data, function (value) {
      const workplace = {} as Dropdown;
      workplace.Value = value.value;

      if (value.name != undefined) {
        workplace.Name = value.name;
      }
      self.workplaceList.push(workplace);
    });
    self.workplaceList = self.appendSelectDropdown2(self.workplaceList);
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null && advancedSearch.WorkplaceCountry != undefined) {
      self.workplaceSelected = self.workplaceList[self.workplaceList.findIndex(x => x.Value.toString() === advancedSearch.WorkplaceCountry)];
    }
  }

  loadReligion() {
    const self = this;
    this.commonservice.getReligion().subscribe(
      data => {
        self.loadReligionAsync(data);
      },
      error => { }
    );
  }

  async loadReligionAsync(data) {
    const self = this;
    self.religionList = [] as Array<Dropdown>;
    self.religionList = self.religionList;
    _.forEach(data, function (value) {
      const religion = {} as Dropdown;
      religion.Value = value.value;

      if (value.name != undefined) {
        religion.Name = value.name.toString();
      }

      self.religionList.push(religion);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null && advancedSearch.Religion != undefined) {
      self.religionSelected = self.religionList[self.religionList.findIndex(x => x.Value.toString() === advancedSearch.Religion)];
    }
  }

  loadQualification() {
    const self = this;
    this.commonservice.getQualification().subscribe(
      data => {
        self.loadQualificationAsync(data);
      },
      error => { }
    );
  }

  async loadQualificationAsync(data) {
    const self = this;
    self.qualificationList = [] as Array<Dropdown>;
    self.qualificationList = self.qualificationList;
    _.forEach(data, function (value) {
      const qualification = {} as Dropdown;
      qualification.Value = value.value;

      if (value.name != undefined) {
        qualification.Name = value.name.toString();
      }

      self.qualificationList.push(qualification);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null && advancedSearch.Qualification != undefined) {
      self.qualificationSelected = self.qualificationList[self.qualificationList.findIndex(x => x.Value.toString() === advancedSearch.Qualification)];
    }
  }

  loadJobCategory() {
    const self = this;
    this.commonservice.getJobCategory().subscribe(
      data => {
        self.loadJobCategoryAsync(data);
      },
      error => { }
    );
  }

  async loadJobCategoryAsync(data) {
    const self = this;
    self.jobCategoryList = [] as Array<Dropdown>;
    self.jobCategoryList = self.jobCategoryList;
    _.forEach(data, function (value) {
      const jobCategory = {} as Dropdown;
      jobCategory.Value = value.value;


      if (value.name != undefined) {
        jobCategory.Name = value.name.toString();
      }

      self.jobCategoryList.push(jobCategory);
    });
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null && advancedSearch.JobCategory != undefined) {
      self.jobCategorySelected = self.jobCategoryList[self.jobCategoryList.findIndex(x => x.Value.toString() === advancedSearch.JobCategory)];
    }
  }

  loadComplexion() {
    const self = this;
    this.commonservice.getComplexion().subscribe(
      data => {
        self.loadComplexionAsync(data);
      },
      error => { }
    );
  }

  async loadComplexionAsync(data) {
    const self = this;
    self.complexionList = [] as Array<Dropdown>;
    _.forEach(data.candidateComplection, function (value) {
      const complexion = {} as Dropdown;
      complexion.Value = value.value;


      if (value.name != undefined) {
        complexion.Name = value.name.toString();
      }

      self.complexionList.push(complexion);
    });
    // self.complexionSelected = _.head(self.complexionList);
    let advancedSearch = self.getSavedSearch();
    if (advancedSearch !== null && advancedSearch.JobCategory != undefined) {
      self.complexionSelected = self.complexionList[self.complexionList.findIndex(x => x.Value.toString() === advancedSearch.Complexion)];
    }
  }

  hideSearchBox() {
    const self = this;
    self.showSearchBox = !self.showSearchBox;
  }

  onPageChange(event: any) {
    this.page = event.first / event.rows + 1;  // Calculate the current page number
    this.size = event.rows;
    this.first = event.first;

    // Fetch data based on the new page number
    if (this.loadFromPresavedSearch) {
      this.searchQuery.PaginationParams = {
        Page: this.page,
        Size: this.size
      }
      this.showLoader = true;
      this.searchFromParams(this.searchQuery);
    }
    else {
      this.search();
    }

  }

  // this is for search query
  search(reset: boolean = false) {
    const self = this;
    self.showLoader = true;


    if (reset) {
      this.page = 1;
    }


    self.searchQuery = {} as SearchQueryBody;
    self.searchQuery.StartAge = (self.ageFromSelected.Value != 0 && self.ageFromSelected.Value) ? self.ageFromSelected.Value.toString() : null;
    self.searchQuery.EndAge = (self.ageToSelected.Value != 0 && self.ageToSelected.Value) ? self.ageToSelected.Value.toString() : null;
    self.searchQuery.StartHeight = (self.heightFromSelected.Value != 0 && self.heightFromSelected.Value) ? self.heightFromSelected.Value.toString() : null;
    self.searchQuery.EndHeight = (self.heightToSelected.Value != 0 && self.heightFromSelected.Value) ? self.heightToSelected.Value.toString() : null;
    self.searchQuery.AdvancedOrQuick = "2";
    if (self.maritalStatusSelected?.Value != 0) {
      self.searchQuery.MaritalStatus = self.maritalStatusSelected?.Value?.toString();
    }

    if (self.physicalStatusSelected?.Value != 0) {
      self.searchQuery.PhysicalStatus = self.physicalStatusSelected?.Value?.toString();
    }

    if (self.parentsSettledSelected?.Value != 0) {
      self.searchQuery.ParentsSettled = self.parentsSettledSelected?.Value?.toString();
    }

    if (self.workplaceSelected?.Value != 0) {
      self.searchQuery.WorkplaceCountry = self.workplaceSelected?.Value?.toString();
    }

    if (self.religionSelected?.Value != 0) {
      self.searchQuery.Religion = self.religionSelected?.Value?.toString();
    }

    if (self.qualificationSelected?.Value != 0) {
      self.searchQuery.Qualification = self.qualificationSelected?.Value?.toString();
    }

    if (self.jobCategorySelected?.Value != 0) {
      self.searchQuery.JobCategory = self.jobCategorySelected?.Value?.toString();
    }

    if (self.complexionSelected?.Value != 0) {
      self.searchQuery.Complexion = self.complexionSelected?.Value?.toString();
    }

    if (self.sortBySource.selectedValue?.Value != 0) {
      self.searchQuery.SortBy = self.sortBySource.selectedValue?.Value?.toString();
    }

    if (self.profileWithImage) {
      self.searchQuery.ShowWithProfile = self.profileWithImage;
    }

    if (self.saveSearch) {
      self.searchQuery.SaveSearch = self.saveSearch;
    }

    if (self.groomOrBride.selectedValue.Value != 0) {
      self.searchQuery.Gender = self.groomOrBride?.selectedValue?.Value?.toString();
    }

    self.searchQuery.WorkplaceCountry = self.candidatesWorkingNationalitySelectedValue?.Value?.toString() === "0" ? null : self.candidatesWorkingNationalitySelectedValue?.Value?.toString();
    self.searchQuery.ParentsSettled = self.fathersWorkingNationalitySelectedValue?.Value?.toString() === "0" ? null : self.fathersWorkingNationalitySelectedValue?.Value?.toString();

    self.searchQuery.SearchDate = new Date();
    self.searchQuery.AdvancedOrQuick = "2";
    self.searchQuery.SearchName = self.searchName.trim().length > 0 ? self.searchName : null;
    self.searchQuery.SearchSavedBy = self.userloggedIn;
    self.searchQuery.UserloggedIn = self.userloggedIn;
    self.searchQuery.PaginationParams = {
      Page: this.page,
      Size: this.size
    }

    self.commonservice
      .advancedSearch(self.searchQuery)
      .subscribe(
        (data: QuickSearchResult) => {
          self.showLoader = false;
          self.runNow(data);
        },
        error => { }
      );

  }

  runNow(data: QuickSearchResult) {
    const self = this;
    this.searchResult = [];
    const updatedData = [] as Array<SearchResult>;
    data.SearchResult.forEach(singleDrop => {
      let quickSearchResult = {} as SearchResult;
      quickSearchResult = singleDrop;
      quickSearchResult.PathToProfile = "./../viewotherprofile";
      updatedData.push(quickSearchResult);
    });

    this.searchResult.push(...updatedData);


    if (data.TotalRecords) {
      self.totalRecords = data.TotalRecords;
    }


    // Reset pagination count if necessary
    this.paginationCount = 0;

    // Manually update the grid's pagination position
    this.first = (this.page - 1) * this.size;

    this.cdr.detectChanges();
  }


  expressInterestPop(event: Event, row: SearchResult) {

    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = row.CandidateUsername;
    eic.userToName = row.CandidateName.toUpperCase();
    self.mtd = self.commonservice.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  sendExpressInterest(event: ExpressInterestModel) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonservice.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  sendMessage(row) {
    const self = this;
    let smi = new SendMessageModelCsKind();
    smi.ToUser = row.candidateUsername;
    smi.FromUser = self.userloggedIn;
    smi.Message = "SEND MESSAGE TO " + row.candidateUsername.toUpperCase();
    self.commonservice.sendMessage(smi).subscribe(data => {
      if (data.InsertWorked) {
        this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
      }
      else {
        this.messageService.add({ severity: 'info', summary: 'Service Message', detail: data.Message });
      }
    });
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (imageThumbnailId != null) {
      self.imageLoader = true;
      self.commonservice.getLargeImage(imageThumbnailId).subscribe(
        data => {
          self.showLargeImage = true;
          self.imageLoader = false;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => { }
      );
    }
    else {

    }
  }


  showSendDialogue(event: Event, mc: SearchResult) {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = mc.CandidateUsername;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonservice.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }

  eventPostSubmit(event: ActionResponse) {
    const self = this;
    self.smg.ShowSendMessage = false;
    if (event.ActionState == true) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }

  bookmarkprofile(row) {
    const self = this;
    let bookmark: AddBookmarkModel = {
      UserWhoIsBookmarking: self.userloggedIn,
      UserWhoIsGettingBookmarked: row.candidateUsername
    }
    let error: any;
    self.commonservice.AddBookMark(bookmark).subscribe(
      data => {
        if (data != undefined) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        error = data?.Message;
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Service Message', detail: error });
      }
    );
  }

  saveSearchName() {
    const self = this;
    self.searchQuery = {} as SearchQueryBody;

    self.searchQuery.StartAge = (self.ageFromSelected && Object.keys(self.ageFromSelected).length > 0 && self.ageFromSelected.Value != 0 && self.ageFromSelected.Value) ? self.ageFromSelected.Value.toString() : null;
    self.searchQuery.EndAge = (self.ageToSelected && Object.keys(self.ageToSelected).length > 0 && self.ageToSelected.Value != 0 && self.ageToSelected.Value) ? self.ageToSelected.Value.toString() : null;
    self.searchQuery.StartHeight = (self.heightFromSelected && Object.keys(self.heightFromSelected).length > 0 && self.heightFromSelected.Value != 0 && self.heightFromSelected.Value) ? self.heightFromSelected.Value.toString() : null;
    self.searchQuery.EndHeight = (self.heightToSelected && Object.keys(self.heightToSelected).length > 0 && self.heightToSelected.Value != 0 && self.heightFromSelected.Value) ? self.heightToSelected.Value.toString() : null;
    self.searchQuery.AdvancedOrQuick = "2";
    if (self.maritalStatusSelected && Object.keys(self.maritalStatusSelected).length > 0 && self.maritalStatusSelected?.Value != 0) {
      self.searchQuery.MaritalStatus = self.maritalStatusSelected?.Value?.toString();
    }
    else {
      self.searchQuery.MaritalStatus = null;
    }

    if (self.physicalStatusSelected && Object.keys(self.physicalStatusSelected).length > 0 && self.physicalStatusSelected?.Value != 0) {
      self.searchQuery.PhysicalStatus = self.physicalStatusSelected?.Value?.toString();
    }
    else {
      self.searchQuery.PhysicalStatus = null;
    }

    if (self.parentsSettledSelected && Object.keys(self.parentsSettledSelected).length > 0 && self.parentsSettledSelected?.Value != 0) {
      self.searchQuery.ParentsSettled = self.parentsSettledSelected?.Value?.toString();
    }
    else {
      self.searchQuery.ParentsSettled = null;
    }

    if (self.workplaceSelected && Object.keys(self.workplaceSelected).length > 0 && self.workplaceSelected?.Value != 0) {
      self.searchQuery.WorkplaceCountry = self.workplaceSelected?.Value?.toString();
    }
    else {
      self.searchQuery.WorkplaceCountry = null;
    }

    if (self.religionSelected && Object.keys(self.religionSelected).length > 0 && self.religionSelected?.Value != 0) {
      self.searchQuery.Religion = self.religionSelected?.Value?.toString();
    }
    else {
      self.searchQuery.Religion = null;
    }

    if (self.qualificationSelected && Object.keys(self.qualificationSelected).length > 0 && self.qualificationSelected?.Value != 0) {
      self.searchQuery.Qualification = self.qualificationSelected?.Value?.toString();
    }
    else {
      self.searchQuery.Qualification = null;
    }

    if (self.jobCategorySelected && Object.keys(self.jobCategorySelected).length > 0 && self.jobCategorySelected?.Value != 0) {
      self.searchQuery.JobCategory = self.jobCategorySelected?.Value?.toString();
    }
    else {
      self.searchQuery.JobCategory = null;
    }

    if (self.complexionSelected && Object.keys(self.complexionSelected).length > 0 && self.complexionSelected?.Value != 0) {
      self.searchQuery.Complexion = self.complexionSelected?.Value?.toString();
    }
    else {
      self.searchQuery.Complexion = null;
    }

    if (self.sortBySource && Object.keys(self.sortBySource).length > 0 && self.sortBySource.selectedValue?.Value != 0) {
      self.searchQuery.SortBy = self.sortBySource.selectedValue?.Value?.toString();
    }
    else {
      self.searchQuery.SortBy = null;
    }

    if (self.profileWithImage) {
      self.searchQuery.ShowWithProfile = self.profileWithImage;
    }
    else {
      self.searchQuery.ShowWithProfile = false;
    }

    if (self.saveSearch) {
      self.searchQuery.SaveSearch = self.saveSearch;
    }
    else {
      self.searchQuery.SaveSearch = false;
    }

    if (self.groomOrBride && Object.keys(self.groomOrBride).length > 0 && self.groomOrBride.selectedValue.Value != 0) {
      self.searchQuery.Gender = self.groomOrBride?.selectedValue?.Value?.toString();
    }
    else {
      self.searchQuery.Gender = "0";
    }

    if (self.candidatesWorkingNationalitySelectedValue && Object.keys(self.candidatesWorkingNationalitySelectedValue).length > 0) {
      self.searchQuery.WorkplaceCountry = self.candidatesWorkingNationalitySelectedValue?.Value?.toString() === "0" ? "" : self.candidatesWorkingNationalitySelectedValue?.Value?.toString();
    }
    else {
      self.searchQuery.WorkplaceCountry = null;
    }

    if (self.fathersWorkingNationalitySelectedValue && Object.keys(self.fathersWorkingNationalitySelectedValue).length > 0) {
      self.searchQuery.ParentsSettled = self.fathersWorkingNationalitySelectedValue?.Value?.toString() === "0" ? "" : self.fathersWorkingNationalitySelectedValue?.Value?.toString();
    }
    else {
      self.searchQuery.ParentsSettled = null;
    }

    self.searchQuery.SearchDate = new Date();
    self.searchQuery.AdvancedOrQuick = "2";
    self.searchQuery.SearchName = self.searchName.trim().length > 0 ? self.searchName : null;
    self.searchQuery.SearchSavedBy = self.userloggedIn;
    self.searchQuery.UserloggedIn = self.userloggedIn;
    self.searchQuery.PaginationParams = {
      Page: this.page,
      Size: this.size
    }

    self.commonservice.SaveSearchAdvancedSearch(self.searchQuery).subscribe(
      data => {
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        setTimeout(() => {
          self.saveSearch = false;
        }, 900);
      },
      error => { }
    );

  }

  searchFromParams(searchParameter: SearchQueryBody) {
    const self = this;
    searchParameter.SaveSearch = false;
    self.commonservice
      .advancedSearch(self.searchQuery)
      .subscribe(
        (data: QuickSearchResult) => {
          self.showPreSavedLoad = false;
          this.showLoader = false;
          self.runNow(data);
        },
        error => { }
      );
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem("AdvancedSearch");
  }

  getSavedSearch(): SearchQueryBody {
    let advancedSearch: SearchQueryBody = JSON.parse(sessionStorage.getItem("AdvancedSearch"));
    return advancedSearch;
  }

  resetSearch() {
    const self = this;
    self.profileWithImage = false;
    self.loadControls();
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;
      self.loadFromAge();
      self.loadToAge();
      self.loadFromHeight();
      self.loadToHeight();
      self.loadCandidatesWorkingNationality();
      self.loadParentsWorkingNationality();
      self.loadGroomAndBrideSource();
      self.loadSortBy();
      self.loadMaritalStatus();
      self.loadPhysicalStatus();
      self.loadFathersPlace();
      self.loadParentsSettledAtPlace();
      self.loadWorkPlace();
      self.loadReligion();
      self.loadQualification();
      self.loadJobCategory();
      self.loadComplexion();
    });
    sessionStorage.removeItem('AdvancedSearch');
  }

  appendSelectDropdown2(incoming: Array<Dropdown>): Array<Dropdown> {
    let returnObject: Array<Dropdown> = [];
    let select: Dropdown = {
      Name: "Select",
      Value: 0
    }
    incoming.unshift(select);
    returnObject.push(...incoming);
    return returnObject;
  }

  navigateToDetails(tc: SearchResult) {
    let link = tc.PathToProfile + "/" + tc.CandidateUsername;
    this.router.navigate([]).then(result => { window.open(link, '_blank'); });
  }

}
