import { Component } from '@angular/core';
import { TabCommunicationService } from './common/reusable/servicecenter/TabCommunicationService';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  title = 'All In the Game';
  subscription: any;
  browserRefresh: boolean;
  private hasFocus = true;
  private isBrowserClosing = false;

  constructor(private localForageService: TabCommunicationService) {
    // window.addEventListener('focus', () => {
    //   this.hasFocus = true;
    // });

    // window.addEventListener('blur', () => {
    //   if (this.hasFocus) {
    //     console.log('Browser refresh detected');
    //   }
    //   this.hasFocus = false;
    // });

    // window.addEventListener('unload', (event) => {
    //   if (this.isBrowserClosing) {
    //     alert("I am being called");
    //     this.localForageService.broadcastAllSessionData();       
    //   }
    // });

    // window.addEventListener('beforeunload', () => {
    //   this.isBrowserClosing = true;
    // });
  }
}
