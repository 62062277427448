<page>
  <form #changePassword="ngForm" (ngSubmit)="onSubmit(changePassword)">
    <div style="padding-top: 16px">
      <p-fieldset legend="Change password">
        <div class="panel panel-default">
          <div class="panel-body">
            <div class="flex py-1">
              <div class="col-4">NEW PASSWORD</div>
              <div class="col-4">
                <input pInputText type="password" name="password1" placeholder="Password" [(ngModel)]="password1"
                  style="width: 100%;" required (input)="validatePasswordMatch()" />
              </div>
              <div class="col-4"></div>
            </div>
            <div class="flex py-1">
              <div class="col-4">CONFIRM PASSWORD</div>
              <div class="col-4">
                <input pInputText type="password" name="password2" placeholder="Confirm Password"
                  [(ngModel)]="password2" style="width: 100%;" required (input)="validatePasswordMatch()" />
              </div>
              <div class="col-4"></div>
            </div>
            <div class="flex py-1">
              <div class="col-4">

              </div>
              <div class="col-1">
                <button enable="disableSubmit" pButton type="submit" color="primary"
                  [disabled]="!password1 || !password2">
                  SUBMIT
                </button>
              </div>
              <div class="col-1">
                <p-button label="RESET" type="button" styleClass="p-button-warning" enable="disableReset"
                  (click)="resetForm()" styleClass="p-button-warning"
                  [disabled]="!password1 && !password2"></p-button>
              </div>
              <div class="col-6">
                <!-- Error message for password mismatch -->
                <div *ngIf="passwordMismatchError" class="error-message">
                  {{ passwordMismatchError }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </p-fieldset>
    </div>
  </form>
  <p-toast position="bottom-right"></p-toast>
  <p-confirmPopup></p-confirmPopup>
</page>