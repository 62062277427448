import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class GaurdService {
    userLoggedIn: string;
    tokeyKey = 'token';
    token: string;
    router: Router;
    constructor(_router: Router) {
        this.router = _router;
    }

    canActivate(): boolean {
        const self = this;
        let notthief = false;      
        const token = self.getLocalToken();
        if (token ===null || this.isObjectEmpty(token)) {
            notthief = false;
            self.router.navigateByUrl('/welcome');
        } else {
            notthief = true;
        }
        return notthief;
    }

    getLocalToken(): string {
        if (!this.token) {
            this.token = sessionStorage.getItem(this.tokeyKey);
        }
        return this.token;
    }

    isObjectEmpty(obj) {
        return JSON.stringify(obj) === "{}";
    }
}
