import { Component, OnInit, Input } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { RecordContactDetailsModel } from "src/app/dto/RecordContactDetailsModel";
import { RegisteredUser } from '../../../view/view.components.domain';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'usertabboard',
  templateUrl: './usertabboard.component.html',
  styleUrls: ['./usertabboard.component.sass']
})
export class UsertabboardComponent implements OnInit {
  activeIndex = 0;
  userWhosInformationIsRequired: string;
  userloggedIn: string;
  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;
    
    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
    });
  }
  handleChange(e) {
    if (e.index === 5) {
      const self = this;
      self.localStore.getCurrentLoggedInUser().then((result) => {
        self.userloggedIn = result.UserId;
        let recordContactDetailsModel = new RecordContactDetailsModel();
        recordContactDetailsModel.ContactViewedOf = this.userWhosInformationIsRequired;
        recordContactDetailsModel.Owner = self.userloggedIn;
        recordContactDetailsModel.VisitedDate = new Date();
        self.commonService.postVisitedProfile(recordContactDetailsModel);
      })
    }
  }
}
