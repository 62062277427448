<page>
  <div style="padding-top: 16px">
    <p-fieldset legend="PHOTOS">     
      <ng-container>
        <div class="panel panel-default">
          <div class="panel-body">
            <ng-container *ngIf="showGrid">
              <p-card header="PROFILE PHOTO">
                <div class="flex-initial justify-content-start gap-3">
                  <div class="col-3">
                    <ng-container *ngIf="!ui?.ShowProfileImageTmb">
                      <p-card header="">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowProfileImageTmb,
                              'img-thumbnail-profile-image':
                                ui?.ShowProfileImageTmb
                            }" (click)="fileinputProfileImage.click()"
                            src="./../../../../../assets/image/upload.png" />
                        </div>
                      </p-card>
                    </ng-container>
                    <ng-container *ngIf="ui?.ShowProfileImageTmb">
                      <p-card header="">
                        <p-card-content style="display: flex; justify-content: center">
                          <div class="image-shell">
                            <img [src]="ui?.CandidateProfileImageTmbCa" class="profile-image"
                              (click)="
                              makeitbig(
                                ui.CandidateProfileImageTmb
                              )
                            " />
                          </div>
                        </p-card-content>
                        <ng-template pTemplate="footer">
                          <div class="flex justify-content-end">
                            <div class="col-2">
                              <img src="../../../../assets/image/editimage.png" alt="" class="image-edit"
                                (click)="fileinputProfileImage.click()" />
                            </div>
                            <div class="col-2">
                              <img src="../../../../assets/image/trashcang.png" alt="" class="image-delete"
                                (click)="deleteProfileImage()" />
                            </div>
                          </div>
                        </ng-template>
                      </p-card>
                    </ng-container>
                  </div>
                </div>
              </p-card>
              <br />
              <p-card header="ALBUM  PHOTO">
                <div class="flex justify-content-start gap-3">
                  <div class="col">
                    <ng-containter *ngIf="!ui?.ShowAlbumImage1Tmb">
                      <p-card header="">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowAlbumImage1TmbCa,
                              'img-thumbnail-profile-image': ui?.ShowAlbumImage1Tmb
                            }" (click)="fileinputAlbumPhoto1.click()" src="./../../../../../assets/image/upload.png" />
                        </div>
                      </p-card>
                    </ng-containter>
                    <ng-containter *ngIf="ui?.ShowAlbumImage1Tmb">
                      <p-card header="">
                        <img [src]="ui?.CandidateAlbumImage1TmbCa" class="album-image"
                          (click)="
                          makeitbig(
                            ui.CandidateAlbumImage1Tmb
                          )
                        " />
                        <div class="flex">
                          <div class="col-2">
                            <img src="../../../../assets/image/editimage.png" alt="" class="image-edit"
                              (click)="fileinputAlbumPhoto1.click()" />
                          </div>
                          <div class="col-2">
                            <img src="../../../../assets/image/trashcang.png" alt="" class="image-delete"
                              (click)="deleteAlbum1Image()" />
                          </div>
                        </div>
                      </p-card>
                    </ng-containter>
                  </div>
                  <div class="col">
                    <ng-containter *ngIf="!ui?.ShowAlbumImage2Tmb">
                      <p-card header="">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowAlbumImage2TmbCa,
                              'img-thumbnail-profile-image': ui?.ShowAlbumImage2Tmb
                            }" (click)="fileinputAlbumPhoto2.click()" src="./../../../../../assets/image/upload.png" />
                        </div>
                      </p-card>
                    </ng-containter>
                    <ng-containter *ngIf="ui?.ShowAlbumImage2Tmb">
                      <p-card header="">


                        <img [src]="ui?.CandidateAlbumImage2TmbCa" class="album-image"
                          (click)="
                        makeitbig(
                          ui.CandidateAlbumImage2Tmb
                        )
                      " />
                        <div class="flex">
                          <div class="col-2">
                            <img src="../../../../assets/image/editimage.png" alt="" class="image-edit"
                              (click)="fileinputAlbumPhoto2.click()" />
                          </div>
                          <div class="col-2">
                            <img src="../../../../assets/image/trashcang.png" alt="" class="image-delete"
                              (click)="deleteAlbum2Image()" />
                          </div>
                        </div>
                      </p-card>
                    </ng-containter>
                  </div>
                  <div class="col">
                    <ng-containter *ngIf="!ui?.ShowAlbumImage3Tmb">
                      <p-card header="">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowAlbumImage3TmbCa,
                              'img-thumbnail-profile-image': ui?.ShowAlbumImage3Tmb
                            }" (click)="fileinputAlbumPhoto3.click()" src="./../../../../../assets/image/upload.png" />
                        </div>
                      </p-card>
                    </ng-containter>
                    <ng-containter *ngIf="ui?.ShowAlbumImage3Tmb">
                      <p-card header="">

                        <img [src]="ui?.CandidateAlbumImage3TmbCa" class="album-image"
                          (click)="
                      makeitbig(
                        ui.CandidateAlbumImage3Tmb
                      )
                    " />


                        <div class="flex">
                          <div class="col-2">
                            <img src="../../../../assets/image/editimage.png" alt="" class="image-edit"
                              (click)="fileinputAlbumPhoto3.click()" />
                          </div>
                          <div class="col-2">
                            <img src="../../../../assets/image/trashcang.png" alt="" class="image-delete"
                              (click)="deleteAlbum3Image()" />
                          </div>
                        </div>
                      </p-card>
                    </ng-containter>
                  </div>
                  <div class="col">
                    <ng-containter *ngIf="!ui?.ShowAlbumImage4Tmb"><p-card header="">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowAlbumImage4Tmb,
                              'img-thumbnail-profile-image': ui?.ShowAlbumImage4Tmb
                            }" (click)="fileinputAlbumPhoto4.click()" src="./../../../../../assets/image/upload.png" />
                        </div>
                      </p-card></ng-containter>
                    <ng-containter *ngIf="ui?.ShowAlbumImage4Tmb">
                      <p-card header="">
                        <img [src]="ui?.CandidateAlbumImage4TmbCa" class="album-image"
                          onError="this.src='../../../../assets/image/404Image.jpeg';" (click)="
                  makeitbig(
                    ui.CandidateAlbumImage4Tmb
                  )
                " />


                        <div class="flex">
                          <div class="col-2">
                            <img src="../../../../assets/image/editimage.png" alt="" class="image-edit"
                              (click)="fileinputAlbumPhoto4.click()" />
                          </div>
                          <div class="col-2">
                            <img src="../../../../assets/image/trashcang.png" alt="" class="image-delete"
                              (click)="deleteAlbum4Image()" />
                          </div>
                        </div>
                      </p-card></ng-containter>
                  </div>
                </div>
              </p-card>
              <br />
              <p-card header="FAMILY PHOTO">
                <div class="flex justify-content-start gap-3">
                  <div class="col-3">
                    <ng-containter *ngIf="!ui?.ShowFamilyImage1Tmb">
                      <p-card header="">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowFamilyImage1Tmb,
                              'img-thumbnail-profile-image':
                                ui?.ShowFamilyImage1Tmb
                            }" (click)="fileinputFamilyPhoto1.click()"
                            src="./../../../../../assets/image/upload.png" />
                        </div>
                      </p-card>
                    </ng-containter>
                    <ng-containter *ngIf="ui?.ShowFamilyImage1Tmb">
                      <p-card header="">


                        <img [src]="ui?.CandidateFamilyImage1TmbCa" class="family-image"
                          (click)="
                    makeitbig(
                      ui.CandidateFamilyImage1Tmb
                    )
                  " />


                        <div class="flex">
                          <div class="col-2">
                            <img src="../../../../assets/image/editimage.png" alt="" class="image-edit"
                              (click)="fileinputFamilyPhoto1.click()" />
                          </div>
                          <div class="col-2">
                            <img src="../../../../assets/image/trashcang.png" alt="" class="image-delete"
                              (click)="deleteFamily1Image()" />
                          </div>
                        </div>
                      </p-card>
                    </ng-containter>
                  </div>
                  <div class="col-3">
                    <ng-containter *ngIf="!ui?.ShowFamilyImage2Tmb"><p-card header="">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowFamilyImage2Tmb,
                              'img-thumbnail-profile-image':
                                ui?.ShowFamilyImage2Tmb
                            }" (click)="fileinputFamilyPhoto2.click()"
                            src="./../../../../../assets/image/upload.png" />
                        </div>
                      </p-card></ng-containter>
                    <ng-containter *ngIf="ui?.ShowFamilyImage2Tmb"><p-card header="">

                        <img [src]="ui?.CandidateFamilyImage2TmbCa" class="family-image"
                          onError="this.src='../../../../assets/image/404Image.jpeg';" (click)="
                          makeitbig(
                            ui.CandidateFamilyImage2Tmb
                          )
                        " />


                        <div class="flex">
                          <div class="col-2">
                            <img src="../../../../assets/image/editimage.png" alt="" class="image-edit"
                              (click)="fileinputFamilyPhoto2.click()" />
                          </div>
                          <div class="col-2">
                            <img src="../../../../assets/image/trashcang.png" alt="" class="image-delete"
                              (click)="deleteFamily2Image()" />
                          </div>
                        </div>
                      </p-card></ng-containter>
                  </div>
                </div>
              </p-card>
            </ng-container>
          </div>

          <div class="flex">
            <div class="col-10"></div>
            <div class="col-2 text-right">
              <button pButton (click)="saveInformation()">SAVE</button>
            </div>
          </div>
          <br />

          <div *ngIf="showLoader" class="overlay-loader">
            <div class="spinner"></div>
          </div>
        </div>

        <div class="image-upload">
          <input #fileinputProfileImage id="fileinputProfileImage" accept="image/jpeg, image/png"
            (change)="onChangeProfileImage($event)" type="file" />
          <input #fileinputAlbumPhoto1 id="fileinputAlbumPhoto1" accept="image/jpeg, image/png"
            (change)="onChangeAlbumImage1($event)" type="file" />
          <input #fileinputAlbumPhoto2 id="fileinputAlbumPhoto2" accept="image/jpeg, image/png"
            (change)="onChangeAlbumImage2($event)" type="file" />
          <input #fileinputAlbumPhoto3 id="fileinputAlbumPhoto3" accept="image/jpeg, image/png"
            (change)="onChangeAlbumImage3($event)" type="file" />
          <input #fileinputAlbumPhoto4 id="fileinputAlbumPhoto4" accept="image/jpeg, image/png"
            (change)="onChangeAlbumImage4($event)" type="file" />
          <input #fileinputFamilyPhoto1 id="fileinputFamilyPhoto1" accept="image/jpeg, image/png"
            (change)="onChangeFamilyImage1($event)" type="file" />
          <input #fileinputFamilyPhoto2 id="fileinputFamilyPhoto2" accept="image/jpeg, image/png"
            (change)="onChangeFamilyImage2($event)" type="file" />
        </div>
      </ng-container>
    </p-fieldset>
  </div>

  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [closable]="true">
    <p-galleria [(value)]="imageUrls" [thumbnailsPosition]="position" [responsiveOptions]="responsiveOptions"
      [containerStyle]="{ 'max-width': '640px' }" [numVisible]="5" [activeIndex]="activeIndex">
      <ng-template pTemplate="item" let-item>
        <img [src]="item" class="image-shell-2" />
      </ng-template>
      <ng-template pTemplate="thumbnail" let-item>
        <div class="grid grid-nogutter justify-content-center">
          <img [src]="item" class="image-thumbnail" />
        </div>
      </ng-template>
    </p-galleria>
  </p-dialog>
  <!-- <ng-containter *ngIf="showFloatingLoader">
    <loader></loader>
  </ng-containter> -->
</page>