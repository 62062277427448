import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { SaveResponse } from "src/app/dto/SaveResponse";
import { GetEditProfileOtherDto } from "src/app/dto/GetEditProfileOtherDto";
import { SetEditProfileOtherDto } from "src/app/dto/SetEditProfileOtherDto";
import { } from '../../view/view.components.domain';
import { EditPageMegaBucket } from 'src/app/dto/EditPageMegaBucket';
import { Dropdown } from 'src/app/dto/Dropdown';
import { LoaderService } from 'src/app/common/reusable/servicecenter/LoaderService';



@Component({
  selector: 'edit-other-profile',
  templateUrl: './edit-other-profile.component.html',
  styleUrls: ['./edit-other-profile.component.css'],
  providers: [MessageService]
})
export class EditOtherProfileComponent implements OnInit {
  @Input() epd: EditPageMegaBucket = {} as EditPageMegaBucket;
  ui: GetEditProfileOtherDto;
  userloggedIn: string;
  srm: SaveResponse;
  uic: EditPageMegaBucket;
  candidateAnnualIncome: Dropdown[] = [];
  candidateAnnualIncomeSelected: Dropdown;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, public loadingService: LoaderService) { }

  ngOnInit(): void {
    const self = this;
    self.ui = {} as GetEditProfileOtherDto;
    self.uic = self.epd;
    self.candidateAnnualIncome.push(...self.uic.AnnualSalary);
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;
      this.loadingService.startLoading();
      self.getDataFromDb();
    });
  }

  getDataFromDb() {
    const self = this;
    self.commonService.getEditProfileOther(self.userloggedIn).subscribe(
      data => {
        self.ui = data;
        if (self.ui.Candidate_Annual_Income != undefined) {
          let annualSelectedValueFromDb = self.uic.AnnualSalary.filter(x => x.Value === self.ui.Candidate_Annual_Income)[0];
          self.candidateAnnualIncomeSelected = self.ui.Candidate_Annual_Income === undefined ? self.uic.AnnualSalary[0] : annualSelectedValueFromDb;
        }
        else {
          self.candidateAnnualIncomeSelected = self.uic.Countries.filter(x => x.Value === 0)[0];
        }
      },
      error => { },
      () => {
        this.loadingService.stopLoading();
      }
    );
  }

  saveInformation() {
    const self = this;
    let setEditProfileOtherDto: SetEditProfileOtherDto = {} as SetEditProfileOtherDto;
    setEditProfileOtherDto.Candidate_Annual_Income = self.candidateAnnualIncomeSelected.Value.toString();
    setEditProfileOtherDto.Candidate_Assets = self.ui.Candidate_Assets;
    setEditProfileOtherDto.Candidate_Sibling_Details = self.ui.Candidate_Sibling_Details;
    setEditProfileOtherDto.Candidate_Username = self.userloggedIn;
    this.commonService.setEditProfileOther(setEditProfileOtherDto).subscribe(
      data => {
        self.srm = data;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.srm.Message });
        self.getDataFromDb();
      },
      error => { }
    );
  }
}
