<div class="chat-window" [class.expanded]="icm.IsExpanded">
    <div class="chat-header">
        <div class="message-box">
            <h3>{{icm.DisplayName}} </h3>
        </div>
        <div class="arrow-box">
            <i [ngClass]="icm.IsExpanded ? 'pi pi-angle-down chat-arrow' : 'pi pi-angle-up chat-arrow'"
                (click)="expandList()"></i>
        </div>
        <div class="close-box">
            <i [ngClass]="icm.IsExpanded ? 'pi pi-times-circle' : 'pi pi-times-circle'" (click)="closeWindow($event)"></i>
        </div>
    </div>
    <div class="search-box" *ngIf="icm.IsExpanded">

    </div>
    <div [ngClass]="icm.IsExpanded ?  'chat-messages' : 'chat-messages-hidden'">
        <div class="message-section">
            <div class="chat-container" #chatContainer>


                <div *ngIf="showLoader">
                    <loader></loader>
                </div>
                <ng-container *ngIf="!showLoader">
                    <div *ngFor="let message of icm.Messages" class="message"
                        [ngClass]="{'sent': message?.SenderID === selectedUser?.UserID, 'received': message?.SenderID !== selectedUser?.UserID}">
                        <div class="message-content">{{ message?.Message }}</div>
                        <div class="message-timestamp">{{ message?.Timestamp | date:'short' }}</div>
                    </div>
                </ng-container>


            </div>
        </div>
        <div class="message-input-section">
            <input type="text" [(ngModel)]="icm.AddText" class="user-chat-input" placeholder="Chat.."
                (keydown)="initiateChat($event)">
        </div>
    </div>
</div>