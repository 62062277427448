<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<div class="flex py-1" *ngIf="!showLoader">
  <div class="col-12">
    <div class="flex py-1 table-container">
      <div class="col-1 text-left font-semibold">Name</div>
      <div class="col-11">{{ ui?.Reference_Ker_Name || "--" }}</div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-1 text-left font-semibold">Address</div>
      <div class="col-11">
        {{ ui?.Reference_Ker_Address || "--" }}
      </div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-1 text-left font-semibold">Phone</div>
      <div class="col-11">{{ ui?.Reference_Ker_Phone || "--" }}</div>
    </div>
  </div>
</div>
