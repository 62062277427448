<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>


<div class="flex py-1 table-container" *ngIf="!showLoader">
  <div class="col-4">
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Name</div>
      <div class="col-6">{{ ui?.candidate_Name || "--" }}</div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Height</div>
      <div class="col-6">
        {{ ui?.candidate_Height || "--" }}
      </div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Gender</div>
      <div class="col-6">{{ ui?.gender || "--" }}</div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold"> Denomination</div>
      <div class="col-6">
        {{ ui?.candidate_Denomination || "--" }}
      </div>
    </div>

    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Education Category</div>
      <div class="col-6">
        {{ ui?.educationCategory || "--" }}
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Occupation</div>
      <div class="col-6">
        {{ ui?.candidate_Occupation || "--" }}
      </div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Weight</div>
      <div class="col-6">
        {{ ui?.candidate_Weight || "--" }}
      </div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Complexion</div>
      <div class="col-6">
        {{ ui?.candidateComplexion || "--" }}
      </div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Martial Status</div>
      <div class="col-6">{{ ui?.maritalstatus || "--" }}</div>
    </div>
  </div>
  <div class="col-4">
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Date of birth</div>
      <div class="col-6">
        {{ ui?.candidates_dob | date : "dd/MM/yyyy" || "--" }}
      </div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold"> Diocese</div>
      <div class="col-6">
        {{ ui?.candidate_Diocese || "--" }}
      </div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Blood Group</div>
      <div class="col-6">{{ ui?.bloodGroup || "--" }}</div>
    </div>
    <div class="flex py-1">
      <div class="col-6 text-left font-semibold">Physical Status</div>
      <div class="col-6">
        {{ ui?.candidatePhysicalStatus || "--" }}
      </div>
    </div>
  </div>
</div>




<!-- </p-card> -->