<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>


<p-fieldset legend="WHO VIEWED MY PROFILE">
  <ng-containter *ngIf="!showLoader">

    <div class="card-container">
      <p-card *ngFor="let item of membersWhoViewedContactDetails
      | paginate
        : {
            itemsPerPage: 5,
            currentPage: membersWhoViewedContactDetailsNumber,
            id: 'MembersWhoViewedContactDetails'
          }" class="card">
        <ng-template pTemplate="header">
          <img [src]="item.ImagefPersonWhoVisitedMe" [ngClass]="[
          item.ImagefPersonWhoVisitedMe
            ? 'bookmark-image img-thumbnail'
            : 'bookmark-image-no-cursor'
        ]" (click)="
          makeitbig(                  
            item.ImageIdofPersonWhoVisitedMe,
              item.PersonWhoVisitedMe
          )
        " />
        </ng-template>
        <ng-template pTemplate="body">
          <div class="flex flex-column gap-1 text-block">
            <div class="flex flex-row flex-wrap">
              <div class="col-5"><span class="font-semibold"> Name </span></div>
              <div class="col-7 book-mark-name">{{item.NameOfPersonWhoVisitedMe }}</div>
            </div>
            <div class="flex flex-row flex-wrap">
              <div class="col-5"><span class="font-semibold"> ID </span></div>
              <div class="col-7 book-mark-id">{{item.PersonWhoVisitedMe }}</div>
            </div>
            <div class="flex flex-row flex-wrap">
              <div class="col-5"><span class="font-semibold"> Date </span></div>
              <div class="col-7 book-mark-id">{{ item.DatePersonVisitedMe | date }}</div>
            </div>
          </div>
        </ng-template>
      </p-card>
    </div>


    <div style="margin-top: 10px; text-align: center">
      <pagination-controls (pageChange)="membersWhoViewedContactDetailsNumber = $event"
        id="MembersWhoViewedContactDetails"></pagination-controls>
    </div>

    <div *ngIf="membersWhoViewedContactDetails?.length === 0" class="no-value-text">
      No members viewed your contact details yet
    </div>

  </ng-containter>


  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>
</p-fieldset>