import { AfterViewChecked, ChangeDetectorRef, Component, CreateEffectOptions, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, WritableSignal, effect, signal } from '@angular/core';
import { ChatService } from '../servicecenter/ChatService';
import { ChatUserListResponse } from 'src/app/dto/usersReadyForChat';
import { ChatResponse } from 'src/app/dto/ChatResponse';
import { AddNewChatMessageRequest } from 'src/app/dto/AddNewChatMessageRequest';
import { SignalRService } from '../servicecenter/SignalRService';
import { User } from 'src/app/private/dashboard/home/home.component.domain';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { IndividualChatmodel } from 'src/app/dto/IndividualChatmodel';

@Component({
  selector: 'individual-chat-window',
  templateUrl: './individual-chat-window.component.html',
  styleUrl: './individual-chat-window.component.css',
  encapsulation: ViewEncapsulation.ShadowDom
})
export class IndividualChatWindowComponent implements OnInit, AfterViewChecked {
  @ViewChild('chatContainer') private chatContainer: ElementRef;
  selectedUser: ChatUserListResponse | null = null;
  isExpanded: boolean = false;
  addText: string = "";
  showLoader: boolean = false;
  showChatWindow: WritableSignal<boolean | null> = signal(false);
  messages: Array<ChatResponse> = [];
  userloggedIn: string;
  public user: User;
  @Input() userData: IndividualChatmodel;
  @Output() close = new EventEmitter<void>();

  icm: IndividualChatmodel = {
    AddText: "",
    DisplayName: "",
    IsExpanded: true,
    Messages: [],
    ShowLoader: false,
    SenderId: "",
    RecipientId: ""
  };;

  onClose(): void {
    this.close.emit();
  }

  constructor(private chatService: ChatService, private signalRService: SignalRService, private localStore: LocalStore, private cdRef: ChangeDetectorRef) {

    effect(() => {
      this.selectedUser = this.chatService.selectedUser();
    }, {
      allowSignalWrites: true
    } as CreateEffectOptions);
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  ngOnInit() {
    const self = this;
    self.user = {} as User;
    this.icm = this.userData;
    this.loadChatMessages();
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
    this.signalRService.addMessageListener((response, message) => {
      this.loadChatMessages();
    });

    this.signalRService.addMessageConfirmationListener((response, message) => {
      this.loadChatMessages();
    });
  }

  expandList() {
    const self = this;
    this.isExpanded = !this.isExpanded;
    this.icm.IsExpanded = !this.icm.IsExpanded;
  }

  closeWindow(event) {
    event.stopPropagation();
    this.close.emit();
  }

  initiateChat(event: KeyboardEvent) {
    if (event.key === 'Enter' && !event.shiftKey) {
      this.sendMessage(this.addText);
      event.preventDefault(); // Prevents the default action (newline in textarea)
    }
  }

  sendMessage(message: string) {
    const newMessage: AddNewChatMessageRequest = {
      SenderID: this.userloggedIn,
      RecipientID: this.selectedUser?.UserID, // You need to set this based on your app logic
      MessageContent: this.icm.AddText
    };
    setTimeout(() => this.scrollToBottom(), 0);
    this.signalRService.sendMessage(newMessage.SenderID, newMessage.RecipientID, this.icm.AddText);
    this.icm.AddText = "";
  }

  loadChatMessages() {
    const sender = this.icm.SenderId;
    const recipient = this.icm.RecipientId; // You need to set this based on your app logic
    if (sender) {
      this.chatService.getChatMessages(recipient, sender).subscribe(
        (messages: ChatResponse[]) => {
          this.messages = [];
          this.messages.push(...messages);
          this.userData = this.icm;
          this.addText = "";
          this.icm.Messages = [];
          this.icm.Messages.push(...messages);
          this.showLoader = false;
          if (!this.icm.IsExpanded) {
            this.icm.IsExpanded = true;
          }
          this.cdRef.detectChanges();
        }
      );
    }
  }

  private scrollToBottom(): void {
    try {
      this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.log('Scroll to bottom failed:', err);
    }
  }

  loadChatMessagesForSpecificUser(recipient: string) {
    const sender = this.chatService.selectedUser()?.UserID;
    if (sender) {
      this.chatService.getChatMessages(sender, recipient).subscribe(
        (messages: ChatResponse[]) => {
          this.messages = [];
          this.messages.push(...messages);
          this.addText = "";
          this.showLoader = false;
        }
      );
    }
  }
}
