<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>

<div class="flex py-1" *ngIf="!showLoader">
  <div class="col-7">
    <div class="flex py-1 table-container">
      <div class="col-6 text-left font-semibold">Candidates Annual Income</div>
      <div class="col-6">
        {{ ui?.Candidate_Annual_Income || "--" }}
      </div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-6 text-left font-semibold">Candidate's Share/Assets</div>
      <div class="col-6">{{ ui?.candidate_Assets || "--" }}</div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-6 text-left font-semibold">
        About Candidate (School/College/institution
        Studied,Acheivements, Hobbies, Family Background, etc)
      </div>
      <div class="col-6">
        {{ ui?.candidate_Background || "--" }}
      </div>
    </div>
  </div>
</div>