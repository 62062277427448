import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { Component, OnInit, HostListener } from '@angular/core';
import { MenuModel } from "src/app/dto/MenuModel";
import { Router } from '@angular/router';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { MenuItem } from 'primeng/api';
@Component({
  selector: 'topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  menuArray: Array<MenuModel>;
  username: string;
  router: Router;
  items: MenuItem[];
  itemclass: string;

  isLaptop: boolean;
  innerWidth = 0;

  screenWidth: number;
  screenHeight: number;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.checkIfLaptop();
  }

  private checkIfLaptop() {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;

    if (screenWidth <= 1366 && screenHeight <= 768) {
      this.isLaptop = true;
    } else {
      this.isLaptop = false;
    }
    this.screenWidth = screenWidth;

    this.screenHeight = screenHeight;
    // get exact screen resolution code
    // const pixelRatio = window.devicePixelRatio || 1;
    // this.screenWidth = window.screen.width * pixelRatio;
    // this.screenHeight = window.screen.height * pixelRatio;
  }


  constructor(private cs: Commonservice, _router: Router, private localStore: LocalStore) {
    const self = this;
    self.menuArray = [];
    // self.localStore.getCurrentLoggedInUser().then((result) => {
    //   self.username = result.UserLoggedIn;;
    // });
    self.username = self.localStore.getCurrentLoggedInUserSync().UserId;
    self.router = _router;
  }

  logOut() {
    const self = this;
    localStorage.clear();
    sessionStorage.clear();
    self.router.navigateByUrl('/welcome');
  }

  ngOnInit() {
    const self = this;
    this.checkIfLaptop();
    this.itemclass = '';
    const myHome = {} as MenuModel;
    myHome.Name = "Home";
    myHome.Path = "/home";
    myHome.IsActive = true;
    myHome.Index = 0;
    self.menuArray.push(myHome);


    const bookMark = {} as MenuModel;
    bookMark.Name = "Bookmark";
    bookMark.Path = "/bookmark";
    bookMark.IsActive = false;
    bookMark.Index = 1;
    self.menuArray.push(bookMark);

    const search = {} as MenuModel;
    search.Name = "Quick Search";
    search.Path = "/quicksearch";
    search.IsActive = false;
    search.Index = 2;
    self.menuArray.push(search);

    // const advancedSearch = {} as MenuModel;
    // advancedSearch.Name = "Advanced Search";
    // advancedSearch.Path = "/advancedsearch";
    // advancedSearch.IsActive = false;
    // advancedSearch.Index = 3;
    // self.menuArray.push(advancedSearch);

    const mymessages = {} as MenuModel;
    mymessages.Name = "Messages Inbox";
    mymessages.Path = "/mymessageinbox";
    mymessages.IsActive = false;
    mymessages.Index = 4;
    self.menuArray.push(mymessages);

    // const myMessageSent = {} as MenuModel;
    // myMessageSent.Name = "messages sent";
    // myMessageSent.Path = "/mymessagesent";
    // myMessageSent.IsActive = false;
    // myMessageSent.Index = 5;
    // self.menuArray.push(myMessageSent);

    // const myMessageTrash = {} as MenuModel;
    // myMessageTrash.Name = "messages trash";
    // myMessageTrash.Path = "/mymesssagetrash";
    // myMessageTrash.IsActive = false;
    // myMessageTrash.Index = 6;
    // self.menuArray.push(myMessageTrash);

    const expresInterest = {} as MenuModel;
    expresInterest.Name = "Express Interest recieved";
    expresInterest.Path = "/recieved";
    expresInterest.IsActive = false;
    expresInterest.Index = 7;
    self.menuArray.push(expresInterest);

    // if (!this.isLaptop) {
    //   const expresInterestSent = {} as MenuModel;
    //   expresInterestSent.Name = "Express Interest Sent";
    //   expresInterestSent.Path = "/sent";
    //   expresInterestSent.IsActive = false;
    //   expresInterestSent.Index = 8;
    //   self.menuArray.push(expresInterestSent);

    //   const expresInterestTrash = {} as MenuModel;
    //   expresInterestTrash.Name = "Express Interest Trash";
    //   expresInterestTrash.Path = "/trash";
    //   expresInterestTrash.IsActive = false;
    //   expresInterestTrash.Index = 9;
    //   self.menuArray.push(expresInterestTrash);


    // }


    const myProfile = {} as MenuModel;
    myProfile.Name = "View Profile";
    myProfile.Path = "/viewprofile";
    myProfile.IsActive = false;
    myProfile.Index = 10;
    self.menuArray.push(myProfile);

    const modifyPassword = {} as MenuModel;
    modifyPassword.Name = "Modify Password";
    modifyPassword.Path = "/modifypassword";
    modifyPassword.IsActive = false;
    modifyPassword.Index = 11;
    self.menuArray.push(modifyPassword);

    const myVault = {} as MenuModel;
    myVault.Name = "Vault";
    myVault.Path = "/storagevault";
    myVault.IsActive = false;
    myVault.Index = 12;
    self.menuArray.push(myVault);

  }

  myFunction() {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  setActive(item: MenuModel, e: MouseEvent) {
    const self = this;

    self.menuArray.every(x => x.IsActive = false);
    self.menuArray.find(x => x.Index === item.Index).IsActive = true;

    self.menuArray.forEach(x => {
    });
    self.router.navigateByUrl(item.Path);
    e.preventDefault();
  }
  disableRightClick(event: MouseEvent): void {
    event.preventDefault();  // Prevent the default context menu
  }
}
