<div class="barelist top-list">

  <div class="my-background-top-bar">
    <div>
      <div class="grid">
        <div class="col-2"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
        <div class="col-2"></div>
        <div class="col-2">
          <div class="grid">
            <div class="col-2"></div>
            <div class="col-5">
              {{ username }}
            </div>
            <div class="col-5 log-out" (click)="logOut()">LogOut</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="mid-space"></div>
    </div>
  </div>
  <div class="menu-container">
    <div class="topnav" id="myTopnav">
      <ng-container *ngFor="let item of menuArray; let i = index">
        <a [routerLink]="[item.Path]" [routerLinkActive]="['active']" (contextmenu)="disableRightClick($event)">{{ item.Name }}
        </a>
      </ng-container>
      <a href="javascript:void(0);" class="icon" (click)="myFunction()">
        <fa-icon [icon]="['fas', 'bars']"></fa-icon>
      </a>
    </div>
  </div>
</div>