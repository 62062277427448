import { Component } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { GetViewProfileWorkAddressDto } from 'src/app/dto/GetViewProfileWorkAddressDto';

@Component({
  selector: 'view-profile-work-address',
  templateUrl: './view-profile-work-address.component.html',
  styleUrl: './view-profile-work-address.component.css'
})
export class ViewProfileWorkAddressComponent {
  userloggedIn: string;
  ui: GetViewProfileWorkAddressDto;
  showLoader = true;

  constructor(private commonService: Commonservice, private localStore: LocalStore) {
  }


  ngOnInit() {
    const self = this;
    self.ui = {} as GetViewProfileWorkAddressDto;
    self.showLoader = true;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;
      self.commonService.getViewProfileWorkAddress(self.userloggedIn).subscribe(
        data => {
          self.ui = data;
          self.showLoader = false;
        },
        error => {}
      );
    });
   
  }
}
