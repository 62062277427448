import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginUserDto } from "src/app/dto/LoginUserDto";
import { CommonNewservice } from 'src/app/common/reusable/servicecenter/commonNewService';
import * as localForage from "localforage";
import { MessageService } from 'primeng/api';
import { LoginResponseModel } from 'src/app/dto/LoginResponseModel';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { UserInformation } from 'src/app/db.service';

localForage.setDriver([
  localForage.INDEXEDDB,
  localForage.LOCALSTORAGE,
  localForage.WEBSQL,
]);

// Using config()
localForage.config({
  driver: [
    localForage.INDEXEDDB,
    localForage.LOCALSTORAGE,
    localForage.WEBSQL,
  ],
  name: 'myApp',     // These fields
  version: 1.0,      // are totally optional
});

@Component({
  selector: 'loginchome',
  templateUrl: './loginchome.component.html',
  styleUrls: ['./loginchome.component.css'],
  providers: [MessageService],
  animations: [
    trigger('flip', [
      state('front', style({
        transform: 'rotateY(0deg)'
      })),
      state('back', style({
        transform: 'rotateY(180deg)'
      })),
      transition('front => back', [
        animate('1s 0s ease-out',
          keyframes([
            style({
              transform: 'perspective(400px) rotateY(0deg)',
              offset: 0
            }),
            style({
              transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotateY(80deg)',
              offset: 0.4
            }),
            style({
              transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotateY(100deg)',
              offset: 0.5
            }),
            style({
              transform: 'perspective(400px) scale3d(0.95, 0.95, 0.95) rotateY(180deg)',
              offset: 0.8
            }),
            style({
              transform: 'perspective(400px) rotateY(180deg)',
              offset: 1
            })
          ]))
      ]),
      transition('back => front', [
        animate('1s 0s ease-in',
          keyframes([
            style({
              transform: 'perspective(400px) rotateY(180deg)',
              offset: 0
            }),
            style({
              transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotateY(100deg)',
              offset: 0.4
            }),
            style({
              transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotateY(80deg)',
              offset: 0.5
            }),
            style({
              transform: 'perspective(400px) scale3d(0.95, 0.95, 0.95) rotateY(0deg)',
              offset: 0.8
            }),
            style({
              transform: 'perspective(400px) rotateY(0deg)',
              offset: 1
            })
          ]))
      ])
    ])
  ]
})
export class LoginchomeComponent implements OnInit {
  router: Router;
  username: string;
  password: string;
  errorThatMessage: string;
  user: LoginUserDto;
  theKey = "UserInformation";
  email: string;
  flipState = 'front';

  showLoginPage: boolean = true;

  constructor(private commonService: CommonNewservice, _router: Router, private messageService: MessageService) {
    this.router = _router;
  }

  ngOnInit() {
    const self = this;
    sessionStorage.clear();
    self.initiateUserInfo();
  }

  initiateUserInfo() {
    const self = this;
    self.password = '';
    self.username = '';
    self.errorThatMessage = '';
    self.user = {} as LoginUserDto;
    self.user.Username = '';
    self.user.Password = '';
  }

  loginHome() {
    const self = this;
    sessionStorage.clear();
    self.user.Username = self.username;
    self.user.Password = self.password;
    self.commonService.login(self.user).subscribe(
      data => {
        self.runNow(data);
      },
      error => {
        self.catchError(error);
      }
    );

  }

  catchError(response: any) {
  }

  runNow(response: LoginResponseModel) {
    const self = this;
    if (response.State === 1) {
      sessionStorage.clear();
      sessionStorage.setItem('token', response.AccessToken);
      sessionStorage.setItem('userLoggedIn', response.UserName);
      let ui = {} as UserInformation;
      ui.DisplayName = response.Name;
      ui.UserId = response.UserName;
      ui.Token = response.AccessToken;
      sessionStorage.setItem("loginResponse", JSON.stringify(response));
      sessionStorage.setItem("userLoggedIn", JSON.stringify(ui));
      self.router.navigateByUrl('/home');
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: response.Msg });
    }
  }

  cancel() {
    const self = this;
    this.showLoginPage = !this.showLoginPage;
  }

  backToHome() {
    this.showLoginPage = !this.showLoginPage;
  }

  SendToRegister() {
    const self = this;
    self.router.navigateByUrl('/register');
  }

  submitReset(event: any) {
    this.commonService.sendResetLink(this.email).subscribe(
      data => {
        if(data.ActivityWorked){
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Mail is send to " + this.email });
        }
        else{
          this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "mail could not be send due to technical issue" });  
        }        
      },
      error => {
        this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "mail could not be send due to technical issue" });
      }
    );
  }
}
