import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ViewOtherProfilePrimaryTab } from "src/app/dto/ViewOtherProfilePrimaryTab";

@Component({
  selector: 'candidate-personal-primary-information',
  templateUrl: './candidate-personal-primary-information.component.html',
  styleUrls: ['./candidate-personal-primary-information.component.css']
})
export class CandidatePersonalPrimaryInformationComponent implements OnInit {
  showLoader = true;
  userloggedIn: string;
  imageToDisplay: HostWindowModel;
  mc: ViewOtherProfilePrimaryTab = {
    UserId: '',
    CandidateGender: '',
    Age: '',
    Complexion: '',
    Height: '',
    Weight: '',
    BodyType: '',
    MaritalStatus: '',
    Physicalstatus: '',
    FamilyStatus: '',
    BloodGroup: ''
  };
  userWhosInformationIsRequired: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;
    
    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getPrimaryUserInformation();
    });
   
  }

  getPrimaryUserInformation(){
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;;
      self.imageToDisplay = {} as HostWindowModel;
      self.commonService.getOtherUserProfilePrimary(self.userWhosInformationIsRequired, self.userloggedIn).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }

}
