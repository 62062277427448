import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ContactDetailViewedByMeModel } from "src/app/dto/ContactDetailViewedByMeModel";

@Component({
  selector: 'home-contact-details-viewed-by-me',
  templateUrl: './home-contact-details-viewed-by-me.component.html',
  styleUrls: ['./home-contact-details-viewed-by-me.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class HomeContactDetailsViewedByMeComponent implements OnInit {
  ContactDetailViewedByYou: ContactDetailViewedByMeModel[] = [];
  userloggedIn: string;
  showLargeImage = false;
  imageToDisplay: HostWindowModel;
  showLoader = false;
  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, private confirmationService: ConfirmationService) { }
  ngOnInit() {
    const self = this;
    self.showLoader = true;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
    // self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = self.userloggedIn;
      self.ContactDetailViewedByYou = [];
      self.imageToDisplay = {} as HostWindowModel;
      self.commonService.getContactDetailViewedByYou(self.userloggedIn).subscribe(
        data => {
          self.ContactDetailViewedByYou = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    // });

  }

  makeitbig(itemId: string, userIdOfPersonIdVisited: string) {
    const self = this;
    if (itemId != null) {
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = userIdOfPersonIdVisited;
        },
        error => {

        }
      );
    }
    else {

    }
  }
}
