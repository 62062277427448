<page>
    <div class="panel panel-default">
        <div class="panel-heading my-title">
            <h3 class="panel-title">RE-REGISTRATION</h3>
        </div>
        <div class="panel-body">
            
        </div>
    </div>

</page>