import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { IndividualChatmodel } from 'src/app/dto/IndividualChatmodel';
import { LocalStore } from '../globalservice/internalflyweight.service';
import { DynamicComponentService } from './DynamicComponentService';
import { environment } from './../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SignalRService {
    private hubConnection: signalR.HubConnection;
    private connectionState = new BehaviorSubject<string>('Disconnected');
    connect = environment.apiUrl + "/chatHub/";

    constructor(
        private localStore: LocalStore,
        private dynamicComponentService: DynamicComponentService
    ) {
        this.createConnection();
        this.handleConnectionChange();
    }

    private createConnection(): void {
        this.hubConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.connect, { withCredentials: false })
            .withAutomaticReconnect([0, 2000, 10000, 30000]) // Customize retry delays
            .build();
    }

    public startConnection(): void {
        if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
            this.hubConnection
                .start()
                .then(() => {
                    console.log('Connection started');
                    this.connectionState.next('Connected');
                    this.joinChat();
                })
                .catch((err) => {
                    console.error(`Error while starting connection: ${err}`);
                    this.connectionState.next('Disconnected');
                    this.reconnect();
                });
        }
    }

    public addMessageListener(callback: (user: string, message: IndividualChatmodel) => void): void {
        this.hubConnection.on('ReceiveMessage', (user, message) => {
            this.handleMessage(callback, user, message);
        });
    }

    public addMessageConfirmationListener(callback: (user: string, message: string) => void): void {
        this.hubConnection.on('MessageSent', (user, message) => this.getBackConfirmation(callback, user, message));
    }

    public sendMessage(senderId: string, receiver: string, message: string): void {
        this.hubConnection.invoke("SendMessage", senderId, receiver, message)
            .then(() => console.log('Message sent successfully'))
            .catch(err => console.error('Error sending message: ', err));
    }

    private handleMessage(callback: (user: string, message: IndividualChatmodel) => void, user: string, message: IndividualChatmodel): void {
        this.dynamicComponentService.createChatWindow(message);
        callback(user, message);
    }

    private getBackConfirmation(callback: (user: string, message: string) => void, user: string, message: string): void {
        callback(user, message);
    }

    private joinChat() {
        let userLoggedIn = this.localStore.getCurrentLoggedInUserSync().UserId;
        if (userLoggedIn) {
            this.hubConnection.invoke('JoinChat', userLoggedIn)
                .catch(err => console.error('Error joining chat: ', err));
        }
    }

    private reconnect(): void {
        setTimeout(() => {
            console.log('Attempting to reconnect...');
            this.startConnection();
        }, 5000);
    }

    private handleConnectionChange(): void {
        this.hubConnection.onreconnecting((error) => {
            console.log('Reconnecting...', error);
            this.connectionState.next('Reconnecting');
        });

        this.hubConnection.onreconnected((connectionId) => {
            console.log('Reconnected. ConnectionId: ', connectionId);
            this.connectionState.next('Connected');
            this.joinChat();
        });

        this.hubConnection.onclose((error) => {
            console.log('Connection closed:', error);
            this.connectionState.next('Disconnected');
            this.reconnect();
        });
    }

    public getConnectionState(): Observable<string> {
        return this.connectionState.asObservable();
    }
}