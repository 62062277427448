<page>
    <div class="text-block">
        <div class="super-title">MEMBERSHIP</div>
        <p-divider type="solid" />
        <p-card header="">
            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-celeftnter h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    Name
                </div>
                <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                    style="flex: 0 0 70%; box-sizing: border-box;">
                    {{memberShipDetails?.MemberName}}
                </div>
            </div>

            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    Duration
                </div>
                <div class="flex align-items-left justify-content-left h-4rem  border-round p-2"
                    style="flex: 0 0 70%; box-sizing: border-box;">
                    {{memberShipDetails?.Duration}}
                </div>
            </div>

            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    Starts on
                </div>
                <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                    style="flex: 0 0 70%; box-sizing: border-box;">
                    {{memberShipDetails?.StartDate | date }}
                </div>
            </div>

            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    Ends on
                </div>
                <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                    style="flex: 0 0 70%; box-sizing: border-box;">
                    {{memberShipDetails?.RenewalDate | date }}
                </div>
            </div>

            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    Extend membership
                </div>
                <div class="flex align-items-left justify-content-left h-4rem border-round"
                    style="flex: 0 0 70%; box-sizing: border-box;">
                    <p-button label="Extend membership" icon="pi pi-cart-plus" (click)="extendLifeLine()"
                        [text]="true" />
                </div>
            </div>

        </p-card>
    </div>


    <p-divider type="solid" *ngIf="showExtend" @slideInOut class="sliding-div" />


    <div *ngIf="showExtend" @slideInOut class="sliding-div">
        <p-card header="">
            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    1 months
                </div>
                <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                    style="flex: 0 0 50%; box-sizing: border-box;">

                </div>
                <div class="flex align-items-left justify-content-left h-4rem  border-round p-2"
                    style="flex: 0 0 20%; box-sizing: border-box; justify-content: flex-end;">
                    <p-button label="Add one month" icon="pi pi-cart-plus" (click)="addPaymentGateway(1)"
                        [text]="true" />
                </div>
            </div>

            <p-divider type="solid" @slideInOut class="sliding-div" />

            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    3 months
                </div>
                <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                    style="flex: 0 0 50%; box-sizing: border-box;">

                </div>
                <div class="flex align-items-left justify-content-left h-4rem  border-round p-2"
                    style="flex: 0 0 20%; box-sizing: border-box; justify-content: flex-end;">
                    <p-button label="Add 3 months" icon="pi pi-cart-plus" (click)="addPaymentGateway(3)"
                        [text]="true" />
                </div>
            </div>

            <p-divider type="solid" @slideInOut class="sliding-div" />

            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    6 months
                </div>
                <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                    style="flex: 0 0 50%; box-sizing: border-box;">
                </div>
                <div class="flex align-items-left justify-content-left h-4rem  border-round p-2"
                    style="flex: 0 0 20%; box-sizing: border-box; justify-content: flex-end;">
                    <p-button label="Add 6 months" icon="pi pi-cart-plus" (click)="addPaymentGateway(6)"
                        [text]="true" />
                </div>
            </div>

            <p-divider type="solid" @slideInOut class="sliding-div" />

            <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                    style="flex: 0 0 30%; box-sizing: border-box;">
                    12 months
                </div>
                <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                    style="flex: 0 0 50%; box-sizing: border-box;">
                </div>
                <div class="flex align-items-left justify-content-left h-4rem  border-round p-2"
                    style="flex: 0 0 20%; box-sizing: border-box; justify-content: flex-end;">
                    <p-button label="Add 12 months" icon="pi pi-cart-plus" (click)="addPaymentGateway(12)"
                        [text]="true" />
                </div>
            </div>
        </p-card>

        <p-divider type="solid" *ngIf="showPaymentGateWay" @slideInOut class="sliding-div" />
        <div *ngIf="showPaymentGateWay" @slideInOut class="sliding-div">
            <div class="table">
                <div class="row">
                    <div class="cell payment-head-title">
                        You have selected
                    </div>
                </div>
                <div class="row">
                    <div class="cell">
                        <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                            <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                                style="flex: 0 0 30%; box-sizing: border-box;">
                                Membership - {{durationSelected.Duration}} : Rs.{{durationSelected.Amount}}
                            </div>
                            <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                                style="flex: 0 0 50%; box-sizing: border-box;">
                            </div>
                            <div class="flex align-items-left justify-content-left h-4rem  border-round p-2"
                                style="flex: 0 0 20%; box-sizing: border-box; justify-content: flex-end;">
                                Your Total: {{durationSelected.Amount}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p-divider type="solid" *ngIf="showPaymentGateWay" @slideInOut class="sliding-div" />
        <p-card header="">
            <div *ngIf="showPaymentGateWay" @slideInOut class="sliding-div">
                <div class="flex flex-row" style="width: 100%; box-sizing: border-box;">
                    <div class="flex align-items-left justify-content-left h-4rem font-bold border-round p-2"
                        style="flex: 0 0 30%; box-sizing: border-box;">

                    </div>
                    <div class="flex align-items-left justify-content-left h-4rem border-round p-2"
                        style="flex: 0 0 50%; box-sizing: border-box;">
                    </div>
                    <div class="flex align-items-left justify-content-left h-4rem  border-round p-2"
                        style="flex: 0 0 20%; box-sizing: border-box; justify-content: flex-end;">
                        <p-button label="Pay" (click)="payMe()" [text]="false" />
                    </div>
                </div>
            </div>
        </p-card>
    </div>


    <p-toast position="bottom-right"></p-toast>
</page>