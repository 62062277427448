<page>
  <ng-containter *ngIf="showLiteLoader">
    <loader></loader>
  </ng-containter>


  <div class="text-block">
    <div class="super-title">
      <div class="flex justify-content-between flex-wrap">
        <div class="flex align-items-center">MESSAGE INBOX</div>

        <div class="flex align-items-center panel-search">
          <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
            (ngModelChange)="globalSearch($event)" />
        </div>
      </div>
    </div>
  </div>

  <div class="panel panel-default b-r-1">
    <div class="panel-header-container">   

      <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">
        <div class="control-section">

          <ng-containter *ngIf="showLoader">
            <container-loader></container-loader>
          </ng-containter>


          <div *ngIf="sortedData.length > 0 && !showLoader">
            <p-table [value]="sortedData" responsiveLayout="scroll" [paginator]="true" [rows]="10"
              [showCurrentPageReport]="true" responsiveLayout="scroll" [rowsPerPageOptions]="[10, 25, 50]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [(selection)]="selectedMessageItems">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>PHOTO</th>
                  <th>FROM</th>
                  <th>SCM ID</th>
                  <th>AGE</th>
                  <th>HEIGHT</th>
                  <th>DENOMINATION</th>
                  <th>RECEIVED ON</th>
                  <th>ACCEPTED</th>
                  <th>ACCEPTED ON</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-grid>
                <tr>
                  <td>
                    <p-tableCheckbox [value]="grid"></p-tableCheckbox>
                  </td>
                  <td>
                    <img [src]="grid.thumbNailImage" (click)="
                          makeitbig(
                            grid.candidateProfileImageTmb,
                            grid.userFromID
                          )
                        " class="profile-image thumbnail-image" />
                  </td>
                  <td>
                    {{ grid.UserFromName }}
                  </td>
                  <td>
                    {{ grid.userFromID}}
                  </td>
                  <td>
                    {{ grid.age ? grid.age + ' Years' : '' }}
                  </td>
                  <td>
                    {{ grid.height}}
                  </td>
                  <td>
                    <ng-container
                      *ngTemplateOutlet="cellTemplate; context: { $implicit: grid.christaintype }"></ng-container>
                  </td>
                  <td>
                    {{ grid.expressedOn | date : "medium" }}
                  </td>
                  <td>
                    <div class="hl">
                      @if(grid?.acceptanceStatus!=undefined && grid?.acceptanceStatus==='1')
                      {
                      Yes
                      }
                      @else if(grid?.acceptanceStatus===undefined)
                      {
                      <div class="hl">
                        <div>
                          Waiting..
                        </div>
                        <div class="di-1">
                          <img pTooltip="VIEW MESSAGE" tooltipPosition="top"
                            src="../../../../assets/image/newicons/icons8-visible-67.png" class="rc-ito-2" alt=""
                            (click)="viewMessage(grid)" />
                        </div>
                      </div>
                      }
                      @else if(grid?.acceptanceStatus===null)
                      {
                      <div class="hl">
                        <div>
                          Waiting..
                        </div>
                        <div class="di-1">
                          <img pTooltip="VIEW MESSAGE" tooltipPosition="top"
                            src="../../../../assets/image/newicons/icons8-visible-67.png" class="rc-ito-2" alt=""
                            (click)="viewMessage(grid)" />
                        </div>
                      </div>
                      }
                      @else{
                      <div class="hl">
                        <div>
                          Waiting..
                        </div>
                        <div class="di-1">
                          <img pTooltip="VIEW MESSAGE" tooltipPosition="top"
                            src="../../../../assets/image/newicons/icons8-visible-67.png" class="rc-ito-2" alt=""
                            (click)="viewMessage(grid)" />
                        </div>
                      </div>
                      }
                    </div>
                  </td>
                  <td>
                    @if(grid.acceptanceOn){
                    {{ grid.acceptanceOn | date : "medium"}}
                    }
                    @if(grid?.acceptanceOn ===undefined || grid?.acceptanceStatus===null){
                    Waiting..
                    }
                  </td>
                  <td class="text-right">
                    <div class="action-box-2 text-right">
                      <div class="i5o-3">
                        <img pTooltip="VIEW PROFILE" tooltipPosition="top"
                          src="../../../../assets/image/newicons/id-card.png" class="rc-ito-2" alt=""
                          (click)="navigateToDetails(grid)" />
                      </div>
                      <div class="i5o-2">
                        <img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
                          src="../../../../assets/image/newicons/love-message-4669.png" class="rc-ito-2" alt=""
                          (click)="expressInterestPop($event, grid)" />
                      </div>
                      <div class="i5o-2">
                        <img pTooltip="SEND MESSAGE" tooltipPosition="top"
                          src="../../../../assets/image/newicons/envelope.png" class="rc-ito-2" alt=""
                          (click)="showSendDialogue(grid)" />
                      </div>
                      <div class="i5o-4">
                        <img pTooltip="BOOKMARK" tooltipPosition="top"
                          src="../../../../assets/image/newicons/bookmark.png" class="rc-ito-2" alt=""
                          (click)="bookmarkprofile(grid)" />
                      </div>
                      <div class="i5o-5">
                        <img pTooltip="DELETE" tooltipPosition="top"
                          src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                          (click)="openSingleDeleteModal($event, grid)" />
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="paginatorleft">
                <p-button type="button" styleClass="p-button-text"></p-button>
              </ng-template>
              <ng-template pTemplate="paginatorright">
                <p-button type="button" styleClass="p-button-text"></p-button>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="grid-footer">
          <div class="row">
            <div class="col-3">
              <span class="label label-primary">Total Count {{ inboxData.length }}</span>
            </div>
            <div class="col-7"></div>
            <div class="col-2 text-right">
              <button pButton *ngIf="sortedData?.length > 0" (click)="openMultiDeleteModal()">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <p-dialog [header]="vmsg.header" [(visible)]="showMessage" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '20vw' }" [draggable]="false" [resizable]="false">
    <p>
      {{ vmsg.modelContent }}
    </p>
    <ng-template pTemplate="footer">
      <p-button (click)="showMessage = false" label="Keep it pending" styleClass="p-button-text"></p-button>
      <p-button (click)="acceptInterest(vmsg)" label="I accept" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>

  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>

  <p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
    <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
      (OnSubmit)="eventPostSubmit($event)"></send-message>
  </p-dialog>

  <p-toast position="bottom-right"></p-toast>
  <p-confirmPopup></p-confirmPopup>

  <ng-template #cellTemplate let-value>
    <td>
      @if (value !== null && value !== undefined) {
      @if (isDate) {
      {{ value | date: "short" }}
      } @else {
      {{ value }}
      }
      } @else {
      Not available
      }
    </td>
  </ng-template>
</page>