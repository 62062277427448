import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { UserSavedSearchResult } from "src/app/dto/UserSavedSearchResult";
import { QuickSearchParam } from 'src/app/private/search/quicks/quicks.component.domain';

@Component({
  selector: 'home-saved-searches',
  templateUrl: './home-saved-searches.component.html',
  styleUrls: ['./home-saved-searches.component.css']
})
export class HomeSavedSearchesComponent implements OnInit {
  savedSearches: Array<UserSavedSearchResult>;
  userloggedIn: string;
  showLargeImage = false;
  userId: string;
  router: Router;
  imageToDisplay: HostWindowModel;
  showLoader = true;

  constructor(private commonService: Commonservice, _router: Router, private localStore: LocalStore) { this.router = _router; }
  ngOnInit() {
    const self = this;

    self.showLargeImage = true;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
    // self.localStore.getCurrentLoggedInUser().then((result) => {
    self.userloggedIn = self.userloggedIn;
    self.savedSearches = [];
    self.commonService.getSavedSearches(self.userloggedIn).subscribe(
      data => {
        self.savedSearches = data;
        self.showLoader = false;
      },
      error => {

      }
    );
    // });

  }

  navigateToSearch(item: UserSavedSearchResult) {
    const self = this;
    switch (item.AdvancedOrQuick) {
      case "1":
        sessionStorage.setItem("QuickSearch", item.QuickSearchParam);
        self.router.navigateByUrl("/quicksearch");
        break;
      case "2":
        sessionStorage.setItem("AdvancedSearch", item.QuickSearchParam);
        self.router.navigateByUrl("/advancedsearch");
        break;
    }
  }
}
