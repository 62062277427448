
import { throwError as observableThrowError } from 'rxjs';

import { finalize, catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { Commonservice } from './commonservice.component';
import { Router } from '@angular/router';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  hideLoader = true;

  constructor(private commonservice: Commonservice,private router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.commonservice.addCrib(true);

    if (req.url.includes('/welcome') || req.headers.has('skip-interceptor')) {
      // If so, pass the request directly without modification
      return next.handle(req);
    }

    const accessToken = this.commonservice.getLocalToken();

    // Clone the request and set the new header in one step
    const authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}` // Use 'Authorization' header for bearer tokens
      }
    });

    return next.handle(authReq).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Redirect to home page
          this.router.navigate(['/']);
        }
        return observableThrowError(error);
      }), finalize(() => {
        this.commonservice.addCrib(false);
      }),) as any;
  }
}
