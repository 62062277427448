<div class="flyer">

    <div class="membership-offers">
        Premium Membership offers
    </div>
    <ul>
        <li>Express Interest</li>
        <li>Send and receive personalized messages</li>
        <li>Store up to 7 images</li>
        <li>Chat with online members</li>
    </ul>
    <ul>
        <li>Bookmark selected prospects</li>
        <li>View full profiles of your prospects</li>
        <li>Contact Details (Verified & Unvarified)</li>
        <li>Customer care support</li>
    </ul>
    <ul>
        <li>Save Searches</li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div>

<div class="image-box">

    <div class="image-case">
        <img src="../../../assets/image/mc/mc1.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc2.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc3.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc4.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc5.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc6.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc7.webp" alt="" class="image-being-displayed-in-box">
    </div>

    <div class="image-case">
        <img src="../../../assets/image/mc/mc8.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc9.webp" alt="" class="image-being-displayed-in-box">
    </div>
    <div class="image-case">
        <img src="../../../assets/image/mc/mc10.webp" alt="" class="image-being-displayed-in-box">
    </div>
</div>
<ng-template #featureCellTemplate let-text="text" let-iconClass="iconClass">
    <div class="feature-cellls">
        <div class="text-field">{{ text }}</div>
        <div class="icon-field"><i class="{{ iconClass }} icon-style"></i></div>
    </div>
</ng-template>