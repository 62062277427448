<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<div class="demo-tab-content"  *ngIf="!showLoader">
    <div class="flex py-1">
      <div class="col-2"></div>
      <div class="col-10"></div>
    </div>
    <div class="flex py-1">
      <div class="col-6 table-container">
        <div class="flex py-1">
          <div class="col-6 text-left font-semibold">Fathers Name</div>
          <div class="col-6">{{ ui?.Father_Name || "--" }}</div>
        </div>
        <div class="flex py-1">
          <div class="col-6 text-left font-semibold">Fathers Native District</div>
          <div class="col-6">
            {{ ui?.Father_NativeDitrict || "--" }}
          </div>
        </div>
        <div class="flex py-1">
          <div class="col-6 text-left font-semibold">Mothers Name</div>
          <div class="col-6">{{ ui?.Mother_Name || "--" }}</div>
        </div>
        <div class="flex py-1">
          <div class="col-6 text-left font-semibold">Mothers Native District</div>
          <div class="col-6">
            {{ ui?.Mother_NativeDistrict || "--" }}
          </div>
        </div>
      </div>
      <div class="col-6 table-container">
        <div class="flex py-1">
          <div class="col-6 text-left font-semibold">Fathers House Name</div>
          <div class="col-6">{{ ui?.Father_HouseName || "--" }}</div>
        </div>
        <div class="flex py-1">
          <div class="col-6 text-left font-semibold">Fathers Occupation</div>
          <div class="col-6">{{ ui?.Father_Occupation || "--" }}</div>
        </div>
        <div class="flex py-1">
          <div class="col-6 text-left font-semibold">Mothers House Name</div>
          <div class="col-6">{{ ui?.Mother_HouseName || "--" }}</div>
        </div>
        <div class="flex py-1">
          <div class="col-6 text-left font-semibold">Mothers Occupation</div>
          <div class="col-6">{{ ui?.mother_Occupation || "--" }}</div>
        </div>
      </div>
    </div>

    <div class="flex py-1 table-container-2">
      <div class="col-3"></div>
      <div class="col-3 font-semibold">Married</div>
      <div class="col-3 font-semibold">UnMarried</div>
      <div class="col-3 font-semibold">Nuns/Priest</div>
    </div>
    <div class="flex py-1">
      <div class="col-3 font-semibold">No. Of Brothers</div>
      <div class="col-3">{{ ui?.Brother_Married }}</div>
      <div class="col-3">{{ ui?.Brothers_Unmarried }}</div>
      <div class="col-3">{{ ui?.Brother_Priest }}</div>
    </div>
    <div class="flex py-1  table-container-2">
      <div class="col-3 font-semibold">No. Of Sisters</div>
      <div class="col-3">{{ ui?.Sisters_Unmarried }}</div>
      <div class="col-3">{{ ui?.Sisters_Unmarried }}</div>
      <div class="col-3">{{ ui?.Sisters_Nun }}</div>
    </div>
  </div>