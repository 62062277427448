<page>
  <ng-containter *ngIf="showLoader">
    <loader></loader>
  </ng-containter>

  <div class="text-block">
    <div class="super-title">MESSAGE SENT</div>

    <div class="panel panel-default">
      <div class="panel-header-container">
        <div class="panel-search">
          <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
            (ngModelChange)="globalSearch($event)" />
        </div>
      </div>

      <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">
        <div class="control-section">
          <p-table [value]="sortedData" responsiveLayout="scroll" [paginator]="true" [rows]="10"
            [showCurrentPageReport]="true" responsiveLayout="scroll" [rowsPerPageOptions]="[10, 25, 50]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [(selection)]="selectedMessageItems">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>Photo</th>
                <th>Name</th>
                <th>Id</th>
                <th>Hieght</th>
                <th>Age</th>
                <th>DENOMINATION</th>
                <th>Send On</th>
                <th>View Status</th>
                <th>Viewed On</th>
                <th>Acceptance Status</th>
                <th>Acceptance On</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-grid>
              <tr>
                <td>
                  <p-tableCheckbox [value]="grid"></p-tableCheckbox>
                </td>
                <td>
                  <img [src]="grid.thumbNailImage" class="thumbnail-image" (click)="
                        makeitbig(grid.candidateProfileImageTmb, grid.userToID)
                      " />
                </td>
                <td> {{ grid.UserToName }} </td>
                <td> {{ grid.userTo }}</td>
                <td> {{ grid.height }}</td>
                <td> {{ grid.age }} Years</td>
                <ng-container
                  *ngTemplateOutlet="cellTemplate; context: { $implicit: grid.christaintype }"></ng-container>
                <td>{{ grid.userExpressedDate | date : "short" }}</td>
                <td>{{ grid.messageStatus }}</td>
                <td> {{ grid.messageStatusChangeDate | date : "short"}}</td>
                <td>
                  @if(grid?.acceptanceStatus!=undefined && grid?.acceptanceStatus==='1')
                  {
                  Yes
                  }
                  @else if(grid?.acceptanceStatus===undefined ||grid?.acceptanceStatus===null ||
                  grid?.acceptanceStatus!=='1')
                  {
                  No
                  }
                </td>
                <td>
                  <ng-container
                    *ngTemplateOutlet="cellTemplate; context: { $implicit: grid.acceptedOn }"></ng-container>
                </td>
                <td>
                  <div class="action-box-2 text-right">
                    <div class="i5o-1">
                      <img pTooltip="VIEW PROFILE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/id-card.png" class="rc-ito-2" alt=""
                        (click)="navigateToDetails(grid)" />
                    </div>
                    <div class="i5o-2">
                      <img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
                        src="../../../../assets/image/newicons/love-message-4669.png" class="rc-ito-2" alt=""
                        (click)="expressInterestPop($event, grid)" />
                    </div>
                    <div class="i5o-3">
                      <img pTooltip="VIEW MESSAGE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/icons8-visible-67.png" class="rc-ito-2" alt=""
                        (click)="viewMessage(grid)" />
                    </div>

                    <div class="i5o-4">
                      <img pTooltip="BOOKMARK" tooltipPosition="top"
                        src="../../../../assets/image/newicons/bookmark.png" class="rc-ito-2" alt=""
                        (click)="bookmarkprofile( grid)" />
                    </div>
                    <div class="i5o-5">
                      <img pTooltip="DELETE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                        (click)="openSingleDeleteModal($event, grid)" />
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="grid-footer">
          <div class="flex py-1">
            <div class="col-1">
              <span class="label label-primary">Total Count {{ sortedData?.length }}</span>
            </div>
            <div class="col-9"></div>
            <div class="col-2">
              <div class="pull-right">
                <button pButton *ngIf="sortedData?.length > 0" (click)="openMultiDeleteModal()">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>




    <p-dialog [header]="vmsg.header" [(visible)]="showMessage" [style]="{ width: '25vw' }">
      <p>{{ vmsg.modelContent }}</p>
      <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="showMessage = false" label="Ok" styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>
  </div>
  <p-toast position="bottom-right"></p-toast>

  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>
  <p-confirmPopup></p-confirmPopup>


  <ng-template #cellTemplate let-value>
    <td>
      @if (value !== null && value !== undefined) {
      @if (isDate(value)) {
      {{ value | date: "short" }}
      } @else {
      {{ value }}
      }
      } @else {
      Not available
      }
    </td>
  </ng-template>
</page>