<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<ng-containter *ngIf="!showLoader">
  <div class="my-horizontal-list">
    <div>
      <div class="table-responsive">
        <table class="table" *ngIf="savedSearches?.length > 0">
          <tr>
            <td>
              <div class="my-horizontal-list">
                <div
                  *ngFor="
                    let item of savedSearches
                      | paginate
                        : {
                            itemsPerPage: 10,
                            currentPage: savedSearchPageNumber,
                            id: 'SavedSearches'
                          }
                  "
                  style="margin-right: 5px"
                >
                  <button
                    class="hyperlink-In-button"
                    (click)="navigateToSearch(item)"
                  >
                    {{ item.searchName }}
                  </button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <pagination-controls
                (pageChange)="savedSearchPageNumber = $event"
                id="SavedSearches"
              >
              </pagination-controls>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="savedSearches?.length === 0" class="no-value-text">
        No saved searches yet
      </div>
    </div>
  </div>
</ng-containter>
