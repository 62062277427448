import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { CandidateReliionBackGround } from "../../../view/CandidateReliionBackGround";

@Component({
  selector: 'candidate-personal-religion-information',
  templateUrl: './candidate-personal-religion-information.component.html',
  styleUrls: ['./candidate-personal-religion-information.component.css']
})
export class CandidatePersonalReligionInformationComponent implements OnInit {
  mc: CandidateReliionBackGround =  {
    Denomination: '',
    Diocese: '',
    CandidateNative: '',
    CityOrDistrict: '',
    CurrentAddress: ''
  };
  showLoader = true;
  userloggedIn: string;
  userWhosInformationIsRequired: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;
    
    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getPrimaryUserInformation();
    });
  }
  getPrimaryUserInformation(){
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;
      self.commonService.getOtherProfileReligiosSocialBackGroundTab(self.userWhosInformationIsRequired).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }

}
