import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { YourRecentVisitsModelOVC } from "src/app/dto/YourRecentVisitsModelOVC";

@Component({
  selector: 'home-my-recent-visits',
  templateUrl: './home-my-recent-visits.component.html',
  styleUrls: ['./home-my-recent-visits.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class HomeMyRecentVisitsComponent implements OnInit {
  yourRecentVisitsOVC: Array<YourRecentVisitsModelOVC>;
  userloggedIn: string;
  showLargeImage = false;
  userId: string;
  showLoader = false;
  imageToDisplay: HostWindowModel;
  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
    // self.localStore.getCurrentLoggedInUser().then((result) => {
    self.userloggedIn = self.userloggedIn;
    self.yourRecentVisitsOVC = [];
    self.imageToDisplay = {} as HostWindowModel;
    self.commonService.getLoggedInUsersRecentVisits(self.userloggedIn).subscribe(
      data => {
        self.yourRecentVisitsOVC = data;
        self.showLoader = false;
      },
      error => {

      }
    );
    // });
  }

  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (itemId != null) {
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {

        }
      );
    }
    else {

    }
  }

}
