<page>
  <ng-containter *ngIf="showFloatingLoader">
    <loader></loader>
  </ng-containter>

  <div>
    <div class="super-title">BOOKMARKS</div>
    <p-divider type="solid"></p-divider>
    <p-card legend="">
      <ng-containter *ngIf="showLoader">
        <container-loader></container-loader>
      </ng-containter>
      <ng-containter *ngIf="!showLoader">
        <mycard>
          <div class="barelist">
            <div *ngFor="
              let bookmark of listOfbookmark | paginate : { itemsPerPage: 10, currentPage: p }
            ">
              <p-card>
                <div class="flex flex-wrap align-items-center justify-content-between py-1">
                  <div class="col-12 md:col-2 mb-2 md:mb-0">
                    <div class="flex justify-content-center md:justify-content-start py-1">
                      <div class="col-6 text-left">
                        <img [src]="bookmark.candidateProfileImageReal"
                          (click)="makeitbig(bookmark.candidateProfileImageTmb, bookmark.userWhoGotBookMarked)"
                          class="profile-image thumbnail-image" [ngClass]="
                          bookmark.candidateProfileImageReal
                            ? 'profile-image thumbnail-image'
                            : 'profile-image thumbnail-image-no-cursor'
                        " alt="Candidate Image" />
                      </div>
                    </div>
                  </div>

                  <div class="col-12 md:col-9">
                    <div class="barelist text-block">
                      <div class="flex flex-column md:flex-row py-1">
                        <div class="col-12 md:col-3">Name</div>
                        <div class="col-12 md:col-9">
                          <strong> : {{ bookmark.candidate_name }}</strong>
                        </div>
                      </div>

                      <div class="flex flex-column md:flex-row py-1">
                        <div class="col-12 md:col-3">ID</div>
                        <div class="col-12 md:col-9">
                          <strong> : {{ bookmark.candidate_Username }}</strong>
                        </div>
                      </div>

                      <div class="flex flex-column md:flex-row py-1">
                        <div class="col-12 md:col-3">Age</div>
                        <div class="col-12 md:col-9">
                          <strong> : {{ bookmark.candidate_Age }}</strong>
                        </div>
                      </div>

                      <div class="flex flex-column md:flex-row py-1">
                        <div class="col-12 md:col-3">Religion</div>
                        <div class="col-12 md:col-9">
                          : {{ bookmark.candidate_Denomination }}
                        </div>
                      </div>

                      <div class="flex flex-column md:flex-row py-1">
                        <div class="col-12 md:col-3">Edu Qualification</div>
                        <div class="col-12 md:col-9">
                          : {{ bookmark.Education }}
                        </div>
                      </div>

                      <div class="flex flex-column md:flex-row py-1">
                        <div class="col-12 md:col-3">Occupation</div>
                        <div class="col-12 md:col-9">
                          : {{ bookmark.candidate_Occupation }}
                        </div>
                      </div>

                      <div class="flex flex-column md:flex-row py-1">
                        <div class="col-12 md:col-3">Work Place</div>
                        <div class="col-12 md:col-9">
                          : {{ bookmark.candidate_Workplace }}
                        </div>
                      </div>

                      <div class="flex flex-column md:flex-row py-1">
                        <div class="col-12 md:col-3">Looking For</div>
                        <div class="col-12 md:col-9">
                          : {{ bookmark.Partner_Expectations_brief }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-12 md:col-1 text-right">
                    <div class="action-box flex justify-content-between md:justify-content-start">
                      <div class="ibo-1 mb-1 md:mb-0">
                        <img pTooltip="VIEW PROFILE" tooltipPosition="top"
                          src="../../../../assets/image/newicons/id-card.png" class="rc-ito-2" alt=""
                          (click)="navigateToDetails(bookmark)" />
                      </div>
                      <div class="ibo-2 mb-1 md:mb-0">
                        <img pTooltip="SEND MESSAGE" tooltipPosition="top"
                          src="../../../../assets/image/newicons/envelope.png" class="rc-ito-2" alt=""
                          (click)="showSendDialogue(bookmark)" />
                      </div>
                      <div class="ibo-3 mb-1 md:mb-0">
                        <img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
                          src="../../../../assets/image/newicons/love-message-4669.png" class="rc-ito-2" alt=""
                          (click)="expressInterestPop($event, bookmark)" />
                      </div>
                      <div class="ibo-4">
                        <img pTooltip="DELETE" tooltipPosition="top"
                          src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                          (click)="removeBookmark($event, bookmark)" />
                      </div>
                    </div>
                  </div>
                </div>
              </p-card>
              <p-divider></p-divider>
            </div>

          </div>

          <ImageModelWindow [tc]="imageToDisplay"></ImageModelWindow>
        </mycard>

        <ng-containter *ngIf="listOfbookmark?.length > 0">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </ng-containter>
      </ng-containter>
    </p-card>

    <p-toast position="bottom-right"></p-toast>

    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>

    <p-dialog header="Send Message" [(visible)]="smg.ShowSendMessage">
      <send-message #SendMessageComponent (hideSendMessageModel)="hideSendMessageWindow($event)"
        (OnSubmit)="eventPostSubmit($event)"></send-message>
    </p-dialog>

    <p-confirmPopup></p-confirmPopup>
  </div>
</page>