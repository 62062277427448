import { Component, OnInit, Input } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ActivatedRoute } from '@angular/router';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { MemberContactDetailsDto } from 'src/app/dto/MemberContactDetailsDto';

@Component({
  selector: 'candidatecontactdetails',
  templateUrl: './candidatecontactdetails.component.html',
  styleUrls: ['./candidatecontactdetails.component.sass']
})
export class CandidatecontactdetailsComponent implements OnInit {
  mc: MemberContactDetailsDto = {
    CandidateCommunicationAddress: '',
    CandidateCurrentAddress: '',
    ContactNumber: '',
    CandidateOfficePhonenNumber: '',
    MobileNumber: '',
    CandidateAltNumber: '',
    CandidatePreferredTimeToCall: '',
    ReferenceNameInKerala: '',
    ReferenceAddressInKerala: '',
    RelationshipWithKeralaReference: '',
    ReferencePhoneNumberInKerala: ''
  };
  userWhosInformationIsRequired: string;
  showLoader = true;
  userloggedIn: string;
  imageToDisplay: HostWindowModel;

  
   constructor(private commonService: Commonservice, private localStore: LocalStore, private _Activatedroute: ActivatedRoute) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;
    
    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getOtherProfilePartnerRequirementTab();
    });
  }

  getOtherProfilePartnerRequirementTab(){
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;;
      self.imageToDisplay = {} as HostWindowModel;
      self.commonService.getMemberContactDetailsTab(self.userWhosInformationIsRequired, self.userloggedIn).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }


}
