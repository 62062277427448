import { Component, OnInit, ViewChild } from '@angular/core';
import { MessagesToDelete } from "../../mymessages/inbox/MessagesToDelete";
import { ResponseFromUserModel } from "../../mymessages/inbox/ResponseFromUserModel";
import { DeleteModel } from "src/app/dto/DeleteModel";
import { ExpressInterestModel } from "src/app/dto/ExpressInterestModel";
import { ExpressInterestSendModel } from "src/app/dto/ExpressInterestSendModel";
import { ViewMessageModel } from "src/app/dto/ViewMessageModel";
import { ExpressInterestCarrier } from "src/app/dto/ExpressInterestCarrier";
import { MessageRecorded } from "src/app/dto/MessageRecorded";
import { ConfirmDeleteCarrier } from "src/app/dto/ConfirmDeleteCarrier";
import { ConfirmModelComponent } from 'src/app/common/reusable/model/confirmModel/model.component';
import { DisplayModelComponent } from 'src/app/common/reusable/model/DisplayComponent/model.component';
import { ResponseModelComponent } from 'src/app/common/reusable/model/confirmWithResponse/model.component';
import { ConfirmModelYesOrNoComponent } from 'src/app/common/reusable/model/confirmYesOrNo/model.component';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { confirmResponseModel } from 'src/app/common/reusable/model/confirmWithResponse/model.component.domain';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ConfirmdeletemodelComponent } from 'src/app/common/reusable/model/confirmdeletemodel/confirmdeletemodel.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { MessageService, ConfirmationService } from 'primeng/api';
import { CommonFunc } from 'src/app/common/reusable/servicecenter/CommonFunc';
import { AddBookmarkModel } from 'src/app/dto/AddBookmarkModel';
@Component({
  selector: 'app-sent',
  templateUrl: './sent.component.html',
  styleUrls: ['./sent.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class ExpressInterestSentComponent implements OnInit {
  messageData: MessageRecorded[];
  sortedData: MessageRecorded[];
  searchText:string="";
  visibleColumns=[];
  selectedMessageItems: MessageRecorded[] = [];
  userloggedIn: string;
  questiontoask: string;
  idToDelete: number[];
  deleteModel: DeleteModel;
  cdcm: ConfirmDeleteCarrier;
  responseFromUserModel: ResponseFromUserModel;
  selectAll: boolean;
  setting: {};
  selectionMode;
  vmsg: ViewMessageModel;
  initialPage: Object;
  mtd: ExpressInterestModel;
  @ViewChild(ConfirmModelComponent, { static: false }) modelWindow: ConfirmModelComponent;
  @ViewChild(DisplayModelComponent, { static: false }) displayWindow: DisplayModelComponent;
  @ViewChild(ResponseModelComponent, { static: false }) responsewindow: ResponseModelComponent;
  @ViewChild(ConfirmModelYesOrNoComponent, { static: false }) confirmyestornowindow: ConfirmModelYesOrNoComponent;
  @ViewChild(ConfirmdeletemodelComponent, { static: false }) cmc: ConfirmdeletemodelComponent;
  displayedColumns = ['UserImage', 'UserFromName', 'userExpressedDate', 'messageStatus', 'ActionColumn'];
  dataSource: any;
  showLargeImage = false;
  imageToDisplay: HostWindowModel;
  userId: string;
  expressInterestModel: ExpressInterestSendModel;
  showMessage = false;
  showLoader = false;

  constructor(private commonService: Commonservice, private commonFunc: CommonFunc, private localStore: LocalStore, private datePipe: DatePipe,
    private router: Router, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  // Load data ones componet is ready
  ngOnInit() {
    const self = this;
    self.initiateInbox();
  }
  initiateInbox() {
    const self = this;
    self.sortedData = [];
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
    self.bindGrid();
    self.questiontoask = 'Are you sure you want to delete?';
    self.idToDelete = [];
    self.deleteModel = {} as DeleteModel;
    self.selectAll = false;
    self.responseFromUserModel = {} as ResponseFromUserModel;
    self.selectionMode = { selectionMode: ['row'] };

    self.cdcm = {} as ConfirmDeleteCarrier;
    self.cdcm.id = 0;
    self.cdcm.show = false;
    self.imageToDisplay = {} as HostWindowModel;
    self.vmsg = {} as ViewMessageModel;
    let columns=["userTo", "UserToName", "age", "height", "christaintype", "userExpressedDate", "messageStatus","messageStatusChangeDate"];
    self.visibleColumns.push(...columns);
  }



  bindGrid() {
    const self = this;
    self.showLoader = true;
    this.commonService.getExpressedInterestSent(self.userloggedIn).subscribe(
      data => {
        self.showLoader = false;
        self.run(data);
      },
      error => { }
    );
  }

  run(data: any) {
    const self = this;
    self.messageData = [];
    self.sortedData = [];
    if (data !== undefined) {
      _.forEach(data, function (value, key) {
        let myData: MessageRecorded;
        myData = {} as MessageRecorded;
        myData = value;
        if (value.messageStatusChangeDate !== null) {
          value.messageStatusChangeDate = self.datePipe.transform(Date.now(), "yyyy-MM-dd");
        }
        else {
          value.messageStatusChangeDate = "Not yet";
        }

        if (value.messageStatus === null) {
          value.messageStatus = 'Not seen';
        }
        myData.pathToProfile = "./../viewotherprofile"
        self.messageData.push(myData);
        self.sortedData.push(myData);
      });
      self.initialPage = { pageSizes: true, pageCount: 4 };
    }
  }

  selectedThemAll() {
  }

  viewMessage(row) {
    const self = this;
    self.vmsg = {} as ViewMessageModel;
    self.vmsg.show = true;
    self.showMessage = true;
    self.vmsg.header = "Interest sent to " + row.UserFromName.toUpperCase();
    self.vmsg.modelContent = row.textMessageFromUser;
    self.vmsg.showHeader = true;
    self.vmsg.showFooter = true;
    self.vmsg.ToUser = row.userToID;
    self.vmsg.TableId = 7;
    self.vmsg.FromUser = row.userFromID;
    self.vmsg.modelFooter = "DATE EXPRESSED: " + this.datePipe.transform(row.userExpressedDate, "yyyy-MM-dd");
  }

  rebindGrid(event) {
    const self = this;
    self.bindGrid();
  }


  openSingleDeleteModal(event: Event, item) {
    const self = this;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let deleteId = {} as DeleteModel;
        deleteId.sqlWhere = item.Id.toString();
        self.commonService.deleteRecordExpressSent(deleteId).subscribe(
          data => {
            self.bindGrid();
          }
        );
      },
      reject: () => {
        //reject action
      }
    });
  }

  openMultiDeleteModal() {
    const self = this;
    let idsToDelete: MessagesToDelete = {} as MessagesToDelete;
    idsToDelete.Ids = [];

    let member = self.selectedMessageItems.length > 1 ? "members" : "member";

    if (self.selectedMessageItems.length > 0) {
      self.confirmationService.confirm({
        target: event.target,
        message: 'Are you sure that you want to delete selected ' + member + ' proposals?',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          self.selectedMessageItems.forEach(element => {
            idsToDelete.Ids.push(element.Id);
          });

          self.commonService.deleteMultipleRecordExpressSent(idsToDelete).subscribe(
            data => {
              if (data.DeleteWorked) {
                this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
              }
              else {
                this.messageService.add({ severity: 'error', summary: 'Service Message', detail: data.Error });
              }
              self.bindGrid();
            }
          );
        },
        reject: () => {
          //reject action
        }
      });
    }
    else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "please select at least one item to delete" });
    }
  }


  runNow() {
    const self = this;
    self.selectAll = false;
    self.bindGrid();
  }

  replyMessage(item) {
    const self = this;
    self.responsewindow.show(item.interestFromUserId);
  }

  responseToMessage(event: confirmResponseModel) {
    const self = this;
    self.responseFromUserModel.userOnlineFromWhomMessageIsBeingSend = self.userloggedIn;
    self.responseFromUserModel.user_To = event.id.toString();
    self.responseFromUserModel.user_ToID = event.reponse.toString();
    self.responseFromUserModel.user_ExpressedDate = new Date();
    self.responseFromUserModel.status = '1';
    self.responseFromUserModel.theTextBeingSend = event.reponse;
    self.commonService.responseMessageFromUser(self.responseFromUserModel).subscribe(
      () => {
        self.runNowResponseMessage();
      },
      error => { }
    );
  }

  deleteExpressSentRecord(event) {
    const self = this;
    let deleteId = {} as DeleteModel;
    deleteId.sqlWhere = event.id.toString();
    if (event.confirmYesOrNo) {
      self.commonService.deleteRecordExpressSent(deleteId).subscribe(
        data => {
          self.bindGrid();
        }
      );
    }
  }

  runNowResponseMessage() {
    const self = this;
    self.bindGrid();
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (imageThumbnailId != null) {
      self.showLoader = true;
      self.commonService.getLargeImage(imageThumbnailId).subscribe(
        data => {
          self.showLargeImage = true;
          self.showLoader = false;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {

        }
      );
    }
    else {

    }
  }

  expressInterestPop(event: Event, row: any) {
    const self = this;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        let eic = new ExpressInterestCarrier();
        eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
        eic.fromId = self.userloggedIn;
        eic.userToId = row.userToID;
        eic.userToName = row.UserToName.toUpperCase();
        self.mtd = self.commonService.expressInterest(eic);
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }
  sendExpressInterest(event: ExpressInterestModel) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonService.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  navigateToDetails(tc: MessageRecorded) {
    let link = tc.pathToProfile + "/" + tc.userFrom;
    this.router.navigate([]).then(result => { window.open(link, '_blank'); });
  }

  globalSearch(event) {
    const self = this;
    let columnsToFilter = self.visibleColumns;
    self.sortedData = self.commonFunc.searchText(self.messageData, event, columnsToFilter);
  }
  isDate(value: any): boolean {
    return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));
  }


  bookmarkprofile(row:MessageRecorded) {
    const self = this;
    let bookmark: AddBookmarkModel = {
      UserWhoIsBookmarking: row.userFromID,
      UserWhoIsGettingBookmarked: row.userToID
    }
    let error: any;
    self.commonService.AddBookMark(bookmark).subscribe(
      data => {
        if (data != undefined) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        error = data?.Message;
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Service Message', detail: error });
      }
    );
  }
}
