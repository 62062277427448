import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { SendMessageComponent } from 'src/app/common/reusable/send-message/send-message.component';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { ActionResponse } from 'src/app/dto/ActionResponse';
import { AddBookmarkModel } from 'src/app/dto/AddBookmarkModel';
import { ExpressInterestCarrier } from 'src/app/dto/ExpressInterestCarrier';
import { ExpressInterestModel } from 'src/app/dto/ExpressInterestModel';
import { ExpressInterestSendModel } from 'src/app/dto/ExpressInterestSendModel';
import { SendMessageGeneric } from 'src/app/dto/SendMessageGeneric';

@Component({
  selector: 'usermenuboard',
  templateUrl: './usermenuboard.component.html',
  styleUrls: ['./usermenuboard.component.sass'],
  providers: [MessageService, ConfirmationService]
})
export class UsermenuboardComponent implements OnInit {
  userloggedIn: string;
  mtd: ExpressInterestModel;
  confirmationTitle: string = "Confirmation";
  expressInterestModel: ExpressInterestSendModel;
  sendMessageText: string;
  userBeingViewed: string;

  smg: SendMessageGeneric;
  @ViewChild('SendMessageComponent', { static: false }) sendMessageComponent: SendMessageComponent;

  constructor(private datePipe: DatePipe, private commonService: Commonservice, private confirmationService: ConfirmationService, private messageService: MessageService, private _Activatedroute: ActivatedRoute, private localStore: LocalStore) { }

  ngOnInit() {
    const self = this;
    self.smg = {} as SendMessageGeneric;
    self.smg.ToUser = "";
    self.smg.FromUser = "";
    self.smg.ShowSendMessage = false;
    self._Activatedroute.params.subscribe(params => {
      self.userBeingViewed = params['id'];
    });
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;
    });
  }

  expressInterestPop(event: Event) {
    const self = this;
    let eic = new ExpressInterestCarrier();
    eic.currentDate = this.datePipe.transform(Date.now(), "yyyy-MM-dd");
    eic.fromId = self.userloggedIn;
    eic.userToId = self.userBeingViewed;
    eic.userToName = self.userBeingViewed.toUpperCase();
    self.mtd = self.commonService.expressInterest(eic);
    self.confirmationTitle = self.mtd.header;
    self.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure that you want to proceed?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        self.sendExpressInterest(self.mtd);
      },
      reject: () => {
        //reject action
      }
    });
  }

  showSendDialogue() {
    const self = this;
    self.smg.ResetForm = true;
    self.sendMessageComponent.resetForm();
    self.smg.ToUser = self.userBeingViewed;
    self.smg.FromUser = self.userloggedIn;
    self.smg.ShowSendMessage = true;
    self.commonService.SetSendMessage(self.smg);
    this.sendMessageText = "";
  }

  sendExpressInterest(event: ExpressInterestModel) {
    const self = this;
    self.expressInterestModel = {} as ExpressInterestSendModel;
    self.expressInterestModel.FromUser = event.FromUser;
    self.expressInterestModel.ToUser = event.ToUser;
    self.expressInterestModel.MessageFromUser = event.modelContent;
    if (event.Accept) {
      self.commonService.sendExpressInterest(self.expressInterestModel).subscribe(data => {
        if (data) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Express Interest Sent To " + self.mtd.sendUserActualName });
        }
        else {
          this.messageService.add({ severity: 'error', summary: 'Service Message', detail: "Failed to send express interest due to technical reasons" });
        }
      });
    }
  }

  bookmarkprofile() {
    const self = this;
    let bookmark: AddBookmarkModel = {
      UserWhoIsBookmarking: self.userloggedIn,
      UserWhoIsGettingBookmarked: self.userBeingViewed
    }
    let error: any;
    self.commonService.AddBookMark(bookmark).subscribe(
      data => {
        if (data != null) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: data.Message });
        }
        error = data?.Message;
      },
      error => {
        this.messageService.add({ severity: 'error', summary: 'Service Message', detail: error });
      }
    );
  }

  hideSendMessageWindow(event: SendMessageGeneric) {
    const self = this;
    self.smg.ShowSendMessage = event.ShowSendMessage;
  }

  eventPostSubmit(event: ActionResponse) {
    const self = this;
    self.smg.ShowSendMessage = false;
    if (event.ActionState == true) {
      this.messageService.add({ severity: 'success', summary: 'Service Message', detail: event.Message });
    }
    else {
      this.messageService.add({ severity: 'error', summary: 'Service Message', detail: event.Message });
    }
  }
}
