<page>
  <ng-containter *ngIf="showLoader">
    <loader></loader>
  </ng-containter>
  <div>
    <div class="super-title">RECEIVED</div>
    <div class="panel panel-default">
      <div class="panel-header-container">
        <div class="panel-search">
          <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
            (ngModelChange)="globalSearch($event)" />
        </div>
      </div>
      <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">
        <div class="control-section">
          <div *ngIf="sortedData.length > 0">
            <p-table [value]="sortedData" responsiveLayout="scroll" [paginator]="true" [rows]="10"
              [showCurrentPageReport]="true" responsiveLayout="scroll" [rowsPerPageOptions]="[10, 25, 50]"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
              [(selection)]="selectedMessageItems">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th>PHOTO</th>
                  <th>ID</th>
                  <th>FROM</th>
                  <th>AGE</th>
                  <th>HEIGHT</th>
                  <th>RECEIVED ON</th>
                  <th>ACCEPTED</th>
                  <th>ACCEPTED ON</th>
                  <th></th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-row>
                <tr>
                  <td>
                    <p-tableCheckbox [value]="row"></p-tableCheckbox>
                  </td>
                  <td>
                    <div *ngIf="row.thumbNailImage">
                      <img [src]="row.thumbNailImage" class="thumbnail-image" (click)="
                            makeitbig(
                              row.candidateProfileImageTmb,
                              row.userFromID
                            )
                          " />
                    </div>
                  </td>
                  <td> {{ row.userFrom }}</td>
                  <td> {{ row.UserFromName }}</td>
                  <td> {{ row.age ? row.age + ' Years' : '' }}</td>
                  <td> {{ row.height }}</td>
                  <td>{{ row.userExpressedDate | date : "medium" }}</td>
                  <td>
                    @if(row.acceptanceStatus){
                    Yes
                    }
                    @else{
                    waiting..
                    <img pTooltip="VIEW MESSAGE" tooltipPosition="top"
                      src="../../../../assets/image/newicons/icons8-visible-67.png" class="rc-ito-2" alt=""
                      (click)="viewMessage(row)" />
                    }
                  </td>
                  <td>
                    <ng-container
                      *ngTemplateOutlet="cellTemplate; context: { $implicit: row.AcceptedOn }"></ng-container>
                  </td>
                  <td>
                    <div class="action-box-2 text-right">
                      <div class="i5o-1">
                        <img pTooltip="VIEW PROFILE" tooltipPosition="top" (click)="navigateToDetails(row)"
                          src="../../../../assets/image/newicons/id-card.png" class="rc-ito-2" alt="" />
                      </div>
                      <div class="i5o-2">
                        <img pTooltip="EXPRESS INTEREST" tooltipPosition="top"
                          src="../../../../assets/image/newicons/love-message-4669.png" class="rc-ito-2" alt=""
                          (click)="expressInterestPop($event, row)" />
                      </div>
                      <div class="i5o-4">
                        <img pTooltip="BOOKMARK" tooltipPosition="top"
                          src="../../../../assets/image/newicons/bookmark.png" class="rc-ito-2" alt=""
                          (click)="bookmarkprofile(row)" />
                      </div>
                      <div class="i5o-5">
                        <img pTooltip="DELETE" tooltipPosition="top"
                          src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                          (click)="openSingleDeleteModal($event, row)" />
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="grid-footer">
          <div class="flex py-1">
            <div class="col-1">
              <span class="label label-primary">Total Count {{ messageData.length }}</span>
            </div>
            <div class="col-9"></div>
            <div class="col-2">
              <div class="text-right">
                <button pButton *ngIf="messageData.length > 0" (click)="openMultiDeleteModal()">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <p-dialog [header]="vmsg.header" [(visible)]="showMessage" [breakpoints]="{ '960px': '75vw' }"
    [style]="{ width: '20vw' }" [draggable]="false" [resizable]="false">
    <p>
      {{ vmsg.modelContent }}
    </p>
    <ng-template pTemplate="footer">
      <p-button (click)="showMessage = false" label="Keep it pending" styleClass="p-button-text"></p-button>
      <p-button (click)="acceptInterest(vmsg)" label="I accept" styleClass="p-button-text"></p-button>
    </ng-template>
  </p-dialog>

  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>
  <p-confirmPopup></p-confirmPopup>
  <p-toast position="bottom-right"></p-toast>

  <ng-template #cellTemplate let-value>
    <td>
      @if (value !== null && value !== undefined) {
      @if (isDate(value)) {
      {{ value | date: "short" }}
      } @else {
      {{ value }}
      }
      } @else {
      Not available
      }
    </td>
  </ng-template>
</page>