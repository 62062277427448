<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<p-fieldset legend="RECENT VISITORS TO MY PROFILE">
  <div *ngIf="recentVisitorsToYourProfile?.length > 0 && !showLoader" class="vertical-list">

    <div class="card-container">
      <p-card *ngFor="let item of recentVisitorsToYourProfile
    | paginate
      : {
          itemsPerPage: 5,
          currentPage: RecentVisitorsToYourProfileNumber,
          id: 'RecentVisitorsToYourProfile'
        }" class="card">
        <ng-template pTemplate="header">
          <img [src]="item.ProfileImageImg" [ngClass]="[
        item.ProfileImageImg
          ? 'bookmark-image img-thumbnail'
          : 'bookmark-image-no-cursor'
      ]" (click)="makeitbig(item.ProfileImage, item.IdOfPersonIVisited)" />
        </ng-template>
        <ng-template pTemplate="body">
          <div class="flex flex-column gap-1 text-block">
            <div class="flex flex-row flex-wrap">
              <div class="col-5"><span class="font-semibold"> Name </span></div>
              <div class="col-7 book-mark-name">{{item.NameOfPersonWhoVisitedMe }}</div>
            </div>
            <div class="flex flex-row flex-wrap">
              <div class="col-5"><span class="font-semibold"> ID </span></div>
              <div class="col-7 book-mark-id">{{item.IdOfPersonIVisited }}</div>
            </div>
            <div class="flex flex-row flex-wrap">
              <div class="col-5"><span class="font-semibold"> Date </span></div>
              <div class="col-7 book-mark-id">{{ item.DateIVisited | date }}</div>
            </div>
          </div>
        </ng-template>
      </p-card>
    </div>
    <div style="margin-top: 10px; text-align: center">
      <pagination-controls (pageChange)="RecentVisitorsToYourProfileNumber = $event"
        id="RecentVisitorsToYourProfile"></pagination-controls>
    </div>

  </div>

  <div *ngIf="recentVisitorsToYourProfile?.length === 0 && !showLoader" class="no-value-text">
    No visitor to your profile yet
  </div>
</p-fieldset>

<p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }">
  <large-image-window [tc]="imageToDisplay"></large-image-window>
</p-dialog>