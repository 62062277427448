import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  // Signals for each device's portrait and landscape orientation
  isIphoneSePortrait = signal<boolean>(false);
  isIphoneSeLandscape = signal<boolean>(false);
  isIphoneXrPortrait = signal<boolean>(false);
  isIphoneXrLandscape = signal<boolean>(false);
  isIphone12ProPortrait = signal<boolean>(false);
  isIphone12ProLandscape = signal<boolean>(false);
  isIphone14ProMaxPortrait = signal<boolean>(false);
  isIphone14ProMaxLandscape = signal<boolean>(false);
  isPixel7Portrait = signal<boolean>(false);
  isPixel7Landscape = signal<boolean>(false);
  isGalaxyS8PlusPortrait = signal<boolean>(false);
  isGalaxyS8PlusLandscape = signal<boolean>(false);
  isGalaxyS20UltraPortrait = signal<boolean>(false);
  isGalaxyS20UltraLandscape = signal<boolean>(false);
  isIpadMiniPortrait = signal<boolean>(false);
  isIpadMiniLandscape = signal<boolean>(false);
  isIpadAirPortrait = signal<boolean>(false);
  isIpadAirLandscape = signal<boolean>(false);
  isIpadProPortrait = signal<boolean>(false);
  isIpadProLandscape = signal<boolean>(false);
  isSurfacePro7Portrait = signal<boolean>(false);
  isSurfacePro7Landscape = signal<boolean>(false);
  isSurfaceDuoPortrait = signal<boolean>(false);
  isSurfaceDuoLandscape = signal<boolean>(false);
  isGalaxyZFold5Portrait = signal<boolean>(false);
  isGalaxyZFold5Landscape = signal<boolean>(false);
  isAsusZenbookFoldPortrait = signal<boolean>(false);
  isAsusZenbookFoldLandscape = signal<boolean>(false);
  isGalaxyA51A71Portrait = signal<boolean>(false);
  isGalaxyA51A71Landscape = signal<boolean>(false);
  isNestHubLandscape = signal<boolean>(false);
  isNestHubMaxLandscape = signal<boolean>(false);
  isDesktopFullHD = signal<boolean>(false);  // 1920x1080
  isDesktopQuadHD = signal<boolean>(false);  // 2560x1440
  isDesktop4K = signal<boolean>(false);      // 3840x2160

  constructor() {
    this.checkScreenSizeAndOrientation();

    // Listen to resize and orientation change events
    window.addEventListener('resize', () => this.checkScreenSizeAndOrientation());
    window.addEventListener('orientationchange', () => this.checkScreenSizeAndOrientation());
  }

  checkScreenSizeAndOrientation(): void {
    const width = window.innerWidth;
    const height = window.innerHeight;

    console.log("height", height);
    console.log("width", width);

    // iPhone SE
    this.isIphoneSePortrait.set(width === 375 && height === 667 && this.isPortrait());
    this.isIphoneSeLandscape.set(width === 667 && height === 375 && this.isLandscape());

    // iPhone XR
    this.isIphoneXrPortrait.set(width === 414 && height === 896 && this.isPortrait());
    this.isIphoneXrLandscape.set(width === 896 && height === 414 && this.isLandscape());

    // iPhone 12 Pro
    this.isIphone12ProPortrait.set(width === 390 && height === 844 && this.isPortrait());
    this.isIphone12ProLandscape.set(width === 844 && height === 390 && this.isLandscape());

    // iPhone 14 Pro Max
    this.isIphone14ProMaxPortrait.set(width === 430 && height === 932 && this.isPortrait());
    this.isIphone14ProMaxLandscape.set(width === 932 && height === 430 && this.isLandscape());

    // Pixel 7
    this.isPixel7Portrait.set(width === 412 && height === 915 && this.isPortrait());
    this.isPixel7Landscape.set(width === 915 && height === 412 && this.isLandscape());

    // Samsung Galaxy S8+
    this.isGalaxyS8PlusPortrait.set(width === 360 && height === 740 && this.isPortrait());
    this.isGalaxyS8PlusLandscape.set(width === 740 && height === 360 && this.isLandscape());

    // Samsung Galaxy S20 Ultra
    this.isGalaxyS20UltraPortrait.set(width === 412 && height === 915 && this.isPortrait());
    this.isGalaxyS20UltraLandscape.set(width === 915 && height === 412 && this.isLandscape());

    // iPad Mini
    this.isIpadMiniPortrait.set(width === 768 && height === 1024 && this.isPortrait());
    this.isIpadMiniLandscape.set(width === 1024 && height === 768 && this.isLandscape());

    // iPad Air
    this.isIpadAirPortrait.set(width === 820 && height === 1180 && this.isPortrait());
    this.isIpadAirLandscape.set(width === 1180 && height === 820 && this.isLandscape());

    // iPad Pro
    this.isIpadProPortrait.set(width === 1024 && height === 1366 && this.isPortrait());
    this.isIpadProLandscape.set(width === 1366 && height === 1024 && this.isLandscape());

    // Surface Pro 7
    this.isSurfacePro7Portrait.set(width === 912 && height === 1368 && this.isPortrait());
    this.isSurfacePro7Landscape.set(width === 1368 && height === 912 && this.isLandscape());

    // Surface Duo
    this.isSurfaceDuoPortrait.set(width === 540 && height === 720 && this.isPortrait());
    this.isSurfaceDuoLandscape.set(width === 720 && height === 540 && this.isLandscape());

    // Galaxy Z Fold 5
    this.isGalaxyZFold5Portrait.set(width === 373 && height === 819 && this.isPortrait());
    this.isGalaxyZFold5Landscape.set(width === 819 && height === 373 && this.isLandscape());

    // Asus Zenbook Fold
    this.isAsusZenbookFoldPortrait.set(width === 960 && height === 1280 && this.isPortrait());
    this.isAsusZenbookFoldLandscape.set(width === 1280 && height === 960 && this.isLandscape());

    // Samsung Galaxy A51/71
    this.isGalaxyA51A71Portrait.set(width === 412 && height === 915 && this.isPortrait());
    this.isGalaxyA51A71Landscape.set(width === 915 && height === 412 && this.isLandscape());

    // Nest Hub (landscape only)
    this.isNestHubLandscape.set(width === 1024 && height === 600);

    // Nest Hub Max (landscape only)
    this.isNestHubMaxLandscape.set(width === 1280 && height === 800);

    // Desktop Full HD
    this.isDesktopFullHD.set(width === 1920 && height === 919 && this.isLandscape());

    // Desktop Quad HD
    this.isDesktopQuadHD.set(width === 2560 && height === 1440 && this.isLandscape());

    // Desktop 4K
    this.isDesktop4K.set(width === 3840 && height === 2160 && this.isLandscape());
  }

  isPortrait(): boolean {
    return window.innerHeight > window.innerWidth;
  }

  isLandscape(): boolean {
    return window.innerWidth > window.innerHeight;
  }
}
