<page>
  <ng-containter *ngIf="showLoader">
    <loader></loader>
  </ng-containter>
  <div class="text-block">
    <div class="super-title">SENT</div>
    <div class="panel panel-default">
      <div class="panel-header-container">
        <div class="panel-search">
          <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
            (ngModelChange)="globalSearch($event)" />
        </div>
      </div>
      <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">
        <div class="control-section">
          <p-table [value]="sortedData" responsiveLayout="scroll" [paginator]="true" [rows]="10"
            [showCurrentPageReport]="true" responsiveLayout="scroll" [rowsPerPageOptions]="[10, 25, 50]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [(selection)]="selectedMessageItems">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>PHOTO</th>
                <th>TO</th>
                <th>ID</th>
                <th>AGE</th>
                <th>HEIGHT</th>
                <th>DENOMINATION</th>
                <th>EXPRESSED ON</th>
                <th>VIEWED</th>
                <th>VIEWED ON </th>
                <th>ACCEPTED</th>
                <th>ACCEPTED ON</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
              <tr>
                <td>
                  <p-tableCheckbox [value]="row"></p-tableCheckbox>
                </td>
                <td>
                  <img [src]="row.thumbNailImage" class="thumbnail-image" (click)="
                        makeitbig(row.candidateProfileImageTmb, row.userFromID)
                      " />
                </td>
                <td>
                  {{ row.userTo }}
                </td>
                <td> {{ row.UserToName}} </td>
                <td> {{ row.age ? row.age + ' Years' : '' }} </td>
                <td> {{ row.height}} </td>
                <td> <ng-container
                    *ngTemplateOutlet="cellTemplate; context: { $implicit: row.christaintype }"></ng-container>
                </td>
                <td>
                  {{ row.userExpressedOn | date : "short" }}
                </td>
                <td>
                  <div class="date-info">
                    <div class="di-1">@if(row.messageStatus==='Seen'){Yes}@else{No}
                    </div>
                  </div>
                </td>
                <td>
                  <ng-container
                    *ngTemplateOutlet="cellTemplate; context: { $implicit: row.messageViewedOn }"></ng-container>
                </td>

                <td>
                  @if(row.acceptanceStatus){Yes}@else{No}
                </td>

                <td>
                  <ng-container
                    *ngTemplateOutlet="cellTemplate; context: { $implicit: row.acceptedByRecipientOn }"></ng-container>
                </td>
                <td>
                  <div class="action-box-2 text-right">
                    <div class="i5o-1">
                      <img pTooltip="VIEW PROFILE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/id-card.png" class="rc-ito-2" alt=""
                        (click)="navigateToDetails(row)" />
                    </div>
                    <div class="i5o-3">
                      <img pTooltip="VIEW MESSAGE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/icons8-visible-67.png" class="rc-ito-2" alt=""
                        (click)="viewMessage(row)" />
                    </div>
                    <div class="i5o-4">
                      <img pTooltip="BOOKMARK" tooltipPosition="top"
                        src="../../../../assets/image/newicons/bookmark.png" class="rc-ito-2" alt=""
                        (click)="bookmarkprofile(row)" />
                    </div>
                    <div class="i5o-5">
                      <img pTooltip="DELETE" tooltipPosition="top"
                        src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                        (click)="openSingleDeleteModal($event, row)" />
                    </div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="grid-footer">
          <div class="flex py-1">
            <div class="col-1">
              <div *ngIf="messageData != undefined">
                <span class="label label-primary">Total Count {{ messageData.length }}</span>
              </div>
            </div>
            <div class="col-9"></div>
            <div class="col-2">
              <div class="pull-right">
                <button pButton *ngIf="messageData != undefined && messageData.length > 0"
                  (click)="openMultiDeleteModal()">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
      <large-image-window [tc]="imageToDisplay"></large-image-window>
    </p-dialog>

    <p-dialog [header]="vmsg.header" [(visible)]="showMessage" [breakpoints]="{ '960px': '75vw' }"
      [style]="{ width: '20vw' }" [draggable]="false" [resizable]="false">
      <p>
        {{ vmsg.modelContent }}
      </p>
      <ng-template pTemplate="footer">
        <p-button (click)="showMessage = false" label="Ok" styleClass="p-button-text"></p-button>
      </ng-template>
    </p-dialog>

    <p-confirmPopup></p-confirmPopup>
    <p-toast position="bottom-right"></p-toast>
  </div>
  <ng-template #cellTemplate let-value let-customMessage="customMessage">
    <td>
      <ng-container *ngIf="value !== null && value !== undefined; else naTemplate">
        <ng-container *ngIf="value !== 'not yet'; else notYetTemplate">
          <ng-container *ngIf="isDate(value); else valueTemplate">
            {{ value | date: 'short' }}
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #naTemplate>{{ customMessage || 'Not available' }}</ng-template>
      <ng-template #notYetTemplate>Not yet</ng-template>
      <ng-template #valueTemplate>{{ value }}</ng-template>
    </td>
  </ng-template>
</page>