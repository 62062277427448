<div ng-if="loading == true">
  <div class="barelist">
    <div>
      <topbar></topbar>
    </div>
    <div>
      <div class="container top-space">
        <div class="formgrid grid">
          <div [ngClass]="{ field: isMobile, 'field col-12 sm:col-6 md:col-4 lg:col-2': !isMobile }">
            <div class="left-menu">
              <menu-search></menu-search>
              <menu-left></menu-left>
            </div>
          </div>
          <div [ngClass]="{ 'field col-12 sm:col-6 md:col-4 lg:col-12': isMobile, 'field col-12 sm:col-6 md:col-4 lg:col-10': !isMobile }">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-window-zone">
      <app-chat-window></app-chat-window>
    </div>
    <div>
      <my-footer></my-footer>
    </div>
  </div>
</div>