import { Component, Input, OnInit } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { Dropdown } from "src/app/dto/Dropdown";
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { SaveResponse } from "src/app/dto/SaveResponse";
import { GetEditProfileFamilyDto } from "src/app/dto/GetEditProfileFamilyDto";
import { GetEditProfilePartnerMegaDto } from "src/app/dto/GetEditProfilePartnerMegaDto";
import { GetEditProfilePartnerDto } from "src/app/dto/GetEditProfilePartnerDto";
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { LoaderService } from 'src/app/common/reusable/servicecenter/LoaderService';

@Component({
  selector: 'app-edit-partner-profile',
  templateUrl: './edit-partner-profile.component.html',
  styleUrls: ['./edit-partner-profile.component.css'],
  providers: [MessageService]
})
export class EditPartnerProfileComponent implements OnInit {
  @Input() epd: EditPageMegaBucket = {} as EditPageMegaBucket;
  ui: GetEditProfilePartnerMegaDto;
  uic: EditPageMegaBucket;
  expectedPartnerEducationSelected: Array<Dropdown> = [];
  PartnetExpectedEmploymentCategorySelected: Array<Dropdown> = [];
  partnerReligionDenominationsConsideredSelected: Array<Dropdown> = [];
  familyStatusSelected: Array<Dropdown> = [];
  martialStatusSelected: Array<Dropdown> = [];
  physicalStatusSelected: Array<Dropdown> = [];
  considerSecondMarriageSelected: Dropdown;
  userloggedIn: string;
  GetEditProfilePartner: GetEditProfilePartnerDto;
  srm: SaveResponse;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, public loadingService: LoaderService) { }

  ngOnInit(): void {
    const self = this;
    self.ui = {} as GetEditProfilePartnerMegaDto;
    self.uic = {} as EditPageMegaBucket;
    self.GetEditProfilePartner = {} as GetEditProfilePartnerDto;
    self.uic = self.epd;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;
      self.getDataFromDb();
    });

  }

  getDataFromDb() {
    const self = this;
    this.loadingService.startLoading();
    self.commonService.getEditProfilePartner(self.userloggedIn).subscribe(
      data => {
        self.ui = data;
        self.GetEditProfilePartner = data.GetEditProfilePartner;
        self.expectedPartnerEducationSelected = data.Partner_Education_categories;
        self.PartnetExpectedEmploymentCategorySelected = data.Partner_Employement_Categories;
        self.partnerReligionDenominationsConsideredSelected = data.Partner_Religion;
        self.familyStatusSelected = data.Partner_Family_Status;
        self.martialStatusSelected = data.Partner_Marital_Status;
        self.physicalStatusSelected = data.Partner_Physical_Status;
        if (self.uic.PartnerConsiderSecondMarriage.find(x => x.Value.toString() === data.GetEditProfilePartner.Partner_SecondMarriage.toString()) != undefined) {
          self.considerSecondMarriageSelected = self.uic.PartnerConsiderSecondMarriage.find(x => x.Value.toString() === data.GetEditProfilePartner.Partner_SecondMarriage.toString());
        }
        else {
          self.considerSecondMarriageSelected = _.first(self.uic.Gender);
        }
      },
      error => { },
      () => {
        this.loadingService.stopLoading();
      }
    );
  }
  saveInformation() {
    const self = this;
    let setEditProfilePartnerMegaDto: GetEditProfilePartnerMegaDto = {} as GetEditProfilePartnerMegaDto;
    let partner_Religion_Selected: Array<Dropdown> = [];
    let partner_Education_categories_Selected: Array<Dropdown> = [];
    let partner_Employement_Categories_Selected: Array<Dropdown> = [];
    let setEditProfilePartner_Selected: GetEditProfilePartnerDto = {} as GetEditProfilePartnerDto;


    partner_Religion_Selected = self.partnerReligionDenominationsConsideredSelected;
    partner_Education_categories_Selected = self.expectedPartnerEducationSelected;
    partner_Employement_Categories_Selected = self.PartnetExpectedEmploymentCategorySelected;
    setEditProfilePartner_Selected = self.GetEditProfilePartner;
    setEditProfilePartner_Selected.Candidate_Username = self.userloggedIn;
    setEditProfilePartner_Selected.Partner_SecondMarriage = self.considerSecondMarriageSelected.Value.toString();


    setEditProfilePartnerMegaDto.GetEditProfilePartner = setEditProfilePartner_Selected;
    setEditProfilePartnerMegaDto.Partner_Education_categories = partner_Education_categories_Selected;
    setEditProfilePartnerMegaDto.Partner_Employement_Categories = partner_Employement_Categories_Selected;
    setEditProfilePartnerMegaDto.Partner_Religion = partner_Religion_Selected;
    setEditProfilePartnerMegaDto.Partner_Family_Status = self.familyStatusSelected;
    setEditProfilePartnerMegaDto.Partner_Marital_Status = self.martialStatusSelected;
    setEditProfilePartnerMegaDto.Partner_Physical_Status = self.physicalStatusSelected;

    this.commonService.setEditProfilePartner(setEditProfilePartnerMegaDto).subscribe(
      data => {
        self.srm = data;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.srm.Message });
        self.getDataFromDb();
      },
      error => { }
    );

  }
}
