<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<ng-containter *ngIf="!showLoader">

  <p-fieldset legend="CONTACTS VIEWED BY ME">
    <div *ngIf="ContactDetailViewedByYou?.length > 0" class="vertical-list">

      <div class="card-container">
        <p-card *ngFor="let item of ContactDetailViewedByYou
      | paginate
        : {
            itemsPerPage: 5,
            currentPage: ContactDetailViewedByYouNumber,
            id: 'ContactDetailViewedByYou'
          }" class="card">
          <ng-template pTemplate="header">
            <img [src]="item.ProfileImage" [ngClass]="[
          item.ProfileImage
            ? 'bookmark-image img-thumbnail'
            : 'bookmark-image-no-cursor'
        ]" (click)="makeitbig(item.ProfileImageId, item.IdOfPersonIVisited)
        " />
          </ng-template>
          <ng-template pTemplate="body">

            <div class="flex flex-column gap-1 text-block">
              <div class="flex flex-row flex-wrap">
                <div class="col-5"><span class="font-semibold"> Name </span></div>
                <div class="col-7 book-mark-name">{{item.NameOfPersonWhoVisitedMe }}</div>
              </div>
              <div class="flex flex-row flex-wrap">
                <div class="col-5"><span class="font-semibold"> ID </span></div>
                <div class="col-7 book-mark-id">{{item.IdOfPersonIVisited }}</div>
              </div>
              <div class="flex flex-row flex-wrap">
                <div class="col-5"><span class="font-semibold"> Date </span></div>
                <div class="col-7 book-mark-id">{{ item.DateIVisited | date }}</div>
              </div>
            </div>
          </ng-template>
        </p-card>
      </div>
      <div style="margin-top: 10px; text-align: center">
        <pagination-controls (pageChange)="ContactDetailViewedByYouNumber = $event"
          id="ContactDetailViewedByYou"></pagination-controls>
      </div>
    </div>
    <div *ngIf="ContactDetailViewedByYou?.length === 0 && !showLoader" class="no-value-text">
      No members viewed your contact details yet
    </div>
  </p-fieldset>
</ng-containter>

<p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }">
  <large-image-window [tc]="imageToDisplay"></large-image-window>
</p-dialog>