import { Component, OnInit } from '@angular/core';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';

@Component({
  selector: 'app-publichome',
  templateUrl: './publichome.component.html',
  styleUrls: ['./publichome.component.css']
})
export class PublichomeComponent implements OnInit {

  constructor(private commonservice: Commonservice) { } 

  ngOnInit() {
    const self = this;
    self.commonservice.addCrib(false);
  }

}
