<page>
  <div style="padding-top: 16px">
    <p-fieldset legend="Hide Profile">
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="hide-box">
            <div class="rc1 text-left">SHOW PROFILE</div>
            <div class="rc2">
              <!-- <p-inputSwitch [(ngModel)]="!userprofileVisible"
                (onChange)="makepersonisibleorhide($event)"></p-inputSwitch> -->
                <!-- <p-inputSwitch [(ngModel)]="!userprofileVisible"></p-inputSwitch> -->
                <p-checkbox [(ngModel)]="userprofileVisible" [binary]="true" inputId="binary" (ngModelChange)="makepersonisibleorhide($event)"></p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>

  <p-toast position="bottom-right"></p-toast>
  <p-confirmPopup></p-confirmPopup>
</page>