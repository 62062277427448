<page>
  <ng-containter *ngIf="this.loadingService.isLoading() else content">
    <loader></loader>
  </ng-containter>
  <ng-template #content>
    <div style="padding-top: 16px" class="text-block">
      <p-fieldset legend="Edit profile">
        <p-accordion>
          <p-accordionTab header="Basic">
            <ng-container *ngIf="loadChild">
              <app-edit-basic-profile [epd]="uic"></app-edit-basic-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab header="Job">
            <ng-container *ngIf="loadChild">
              <app-edit-job-profile [epd]="uic"></app-edit-job-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab header="Family">
            <ng-container *ngIf="loadChild">
              <app-edit-family-profile [epd]="uic"></app-edit-family-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab header="Partner">
            <ng-container *ngIf="loadChild">
              <app-edit-partner-profile [epd]="uic"></app-edit-partner-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab header="References">
            <ng-container *ngIf="loadChild">
              <app-edit-reference-profile></app-edit-reference-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab header="Other">
            <ng-container *ngIf="loadChild">
              <edit-other-profile [epd]="uic"></edit-other-profile>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab header="Home Address">
            <ng-container *ngIf="loadChild">
              <edit-home-address></edit-home-address>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab header="Work Address">
            <ng-container *ngIf="loadChild">
              <edit-work-address [epd]="uic"></edit-work-address>
            </ng-container>
          </p-accordionTab>
          <p-accordionTab header="Postal Address">
            <ng-container *ngIf="loadChild">
              <edit-postal-address [epd]="uic"></edit-postal-address>
            </ng-container>
          </p-accordionTab>
        </p-accordion>
      </p-fieldset>
    </div>
  </ng-template>
</page>