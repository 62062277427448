<page>
  <div style="padding-top: 16px"  class="text-block">
    <p-fieldset legend="PROFILE">
      <p-tabView  [(activeIndex)]="activeIndex">
        <p-tabPanel header="Basic">
          <view-profile-basic></view-profile-basic>
        </p-tabPanel>
        <p-tabPanel header="Job Information">
          <view-profile-job-information></view-profile-job-information>
        </p-tabPanel>
        <p-tabPanel header="Family Details">
          <view-profile-family-details></view-profile-family-details>
        </p-tabPanel>
        <p-tabPanel header="Post">
          <view-profile-post></view-profile-post>
        </p-tabPanel>   
        <p-tabPanel header="Home Address">
          <view-profile-home-address></view-profile-home-address>
        </p-tabPanel>   
        <p-tabPanel header="Work Address">
          <view-profile-work-address></view-profile-work-address>
        </p-tabPanel>     
        <p-tabPanel header="Other">
          <view-profile-other></view-profile-other>
        </p-tabPanel>
        <p-tabPanel header="Partner Preferences">
          <view-profile-partner-preference></view-profile-partner-preference>
        </p-tabPanel>
        <p-tabPanel header="Reference In Kerala">
          <view-profile-reference-in-kerala></view-profile-reference-in-kerala>
        </p-tabPanel>
      </p-tabView>
    </p-fieldset>
  </div>
</page>
