<ng-containter *ngIf="showLoader">
  <container-loader></container-loader>
</ng-containter>
<div class="flex py-1" *ngIf="!showLoader">
  <div class="col-12">
   
    <div class="flex py-1 table-container">
      <div class="col-2 text-left font-semibold">Expected Education</div>
      <div class="col-10">
        {{ ui?.Education || "--" }}
      </div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-2 text-left font-semibold">Expected Employment</div>
      <div class="col-10">
        {{ ui?.Employment || "--" }}
      </div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-2 text-left font-semibold">
        Expected Denominations
      </div>
      <div class="col-10">
        {{ ui?.Denominations || "--" }}
      </div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-2 text-left font-semibold">Expect Second Marriage</div>
      <div class="col-10">
        {{ ui?.OpinionOnSecondMarriage || "--" }}
      </div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-2 text-left font-semibold">Expected Physical Status</div>
      <div class="col-10">{{ ui?.ExpectedPhysicalStatus || "--" }}</div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-2 text-left font-semibold">Expected Marital Status</div>
      <div class="col-10">{{ ui?.ExpectedMaritalStatus || "--" }}</div>
    </div>
    <div class="flex py-1 table-container">
      <div class="col-2 text-left font-semibold">
        Brief Narration About Your Expectations
      </div>
      <div class="col-10">
        {{ ui?.BriefNaration || "--" }}
      </div>
    </div>
  </div>
</div>