import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CommonFunc {
    searchText(array, text, columnsToSearch) {
        text = text.toLowerCase();
        let retulstantArray = array.filter(function (o) {
            let resultfromColumnSearch = columnsToSearch.some(function (k) {
                let resultArray: boolean = false;
                if (o[k] != undefined) {
                    resultArray = o[k].toString().toLowerCase().indexOf(text.toString().toLowerCase()) !== -1;
                }
                return resultArray;
            });
            return resultfromColumnSearch;
        });
        return retulstantArray;
    }

    searchTextFromStringArray(strArray, text){
        text = text.toLowerCase();
        let retulstantArray =  strArray.filter(x=>x.toLowerCase().includes(text));
        return retulstantArray;
    }
}
