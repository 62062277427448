import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { RegisteredUser } from '../../../view/view.components.domain';
import { HostmodelComponent } from 'src/app/common/reusable/model/hostmodel/hostmodel.component';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { MemberUserBoardDOtherViewto } from 'src/app/dto/MemberUserBoardDto';
import { ActivatedRoute } from '@angular/router';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';

@Component({
  selector: 'userboard',
  templateUrl: './userboard.component.html',
  styleUrls: ['./userboard.component.scss']
})
export class UserboardComponent implements OnInit {
  userId: string;
  imageToDisplay: HostWindowModel;
  mc: MemberUserBoardDOtherViewto = {
    candidateProfileImageTmb: "",
    CandidateProfileImageReal: "",
    CandidateUserId: '',
    CandidateName: "",
    ProfileSubmittersRelation: "",
    CandidateLastLogin: undefined,
    Age: "",
    CandidateHeight: "",
    CandidateComplexion: "",
    CandidateDenomination: "",
    CandidateEducationSubject: ""
  }
  showLoader = true;
  userWhosInformationIsRequired: string;
  userloggedIn: string;

  @ViewChild(HostmodelComponent, { static: false }) imageModelWindow: HostmodelComponent;
  constructor(private commonservice: Commonservice, private _Activatedroute: ActivatedRoute, private localStore: LocalStore) { }

  ngOnInit() {
    const self = this;
    self.showLoader = true;

    this._Activatedroute.params.subscribe(params => {
      this.userWhosInformationIsRequired = params['id'];
      self.getOtherProfilePartnerRequirementTab();
    });
  }

  getOtherProfilePartnerRequirementTab() {
    const self = this;
    self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = result.UserId;;
      self.imageToDisplay = {} as HostWindowModel;
      self.commonservice.getMemberUserBoardInformation(self.userWhosInformationIsRequired).subscribe(
        data => {
          self.mc = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    });
  }

  makeitbig(imageThumbnailId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (imageThumbnailId != null) {
      self.commonservice.getLargeImage(imageThumbnailId).subscribe(
        data => {
          self.imageModelWindow.tc.MainImage = data.MainImage;
          self.imageModelWindow.tc.show = true;
          self.imageModelWindow.tc.ThumbNailImages = data.ThumbNails;
          self.imageModelWindow.tc.RouterPath = "/../viewotherprofile";
          self.imageModelWindow.tc.UserId = self.userId;
        },
        error => { }
      );
    }
    else {
      // self.snackBar.open('No Image', "Ok", {
      //   duration: 5000,
      // });
    }
  }
}
