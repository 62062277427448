import { Component, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { RecentVisitorsToMyProfileModel } from "src/app/dto/RecentVisitorsToMyProfileModel";

@Component({
  selector: 'home-recent-visitor-to-my-profile',
  templateUrl: './home-recent-visitor-to-my-profile.component.html',
  styleUrls: ['./home-recent-visitor-to-my-profile.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class HomeRecentVisitorToMyProfileComponent implements OnInit {
  recentVisitorsToYourProfile: Array<RecentVisitorsToMyProfileModel>;
  userloggedIn: string;
  RecentVisitorsToYourProfileNumber = 1;
  showLargeImage = false;
  userId: string;
  imageToDisplay: HostWindowModel;
  showLoader = false;
  
  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, private confirmationService: ConfirmationService) { }

  ngOnInit() {
    const self = this;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
    // self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn =  self.userloggedIn;
      self.recentVisitorsToYourProfile = [];
      self.imageToDisplay = {} as HostWindowModel;
      self.showLoader=true;
      self.commonService.getRecentVisitorsToYourProfile(self.userloggedIn).subscribe(
        data => {
          self.recentVisitorsToYourProfile = data;
          self.showLoader=false;
        },
        error => {

        }
      );
    // });
  
  }

  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (itemId != null) {
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {
          
        }
      );
    }
    else {
     
    }
  }
}
