import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { HostWindowModel } from 'src/app/common/reusable/model/hostmodel/hostmodel.domain';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { MembersWhoViewedMyContactDetailsModel } from "src/app/dto/MembersWhoViewedMyContactDetailsModel";

@Component({
  selector: 'home-members-who-viewed-contact-details',
  templateUrl: './home-members-who-viewed-contact-details.component.html',
  styleUrls: ['./home-members-who-viewed-contact-details.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class HomeMembersWhoViewedContactDetailsComponent implements OnInit {
  membersWhoViewedContactDetails: Array<MembersWhoViewedMyContactDetailsModel>;
  userloggedIn: string;
  showLargeImage = false;
  userId: string;
  imageToDisplay: HostWindowModel;
  showLoader = false;


  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService, private confirmationService: ConfirmationService) { }


  ngOnInit() {
    const self = this;
    self.showLoader = true;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
    // self.localStore.getCurrentLoggedInUser().then((result) => {
      self.userloggedIn = self.userloggedIn;
      self.membersWhoViewedContactDetails = [];
      self.imageToDisplay = {} as HostWindowModel;
      self.commonService.getMembersWhoViewedMyContactDetails(self.userloggedIn).subscribe(
        data => {
          self.membersWhoViewedContactDetails = data;
          self.showLoader = false;
        },
        error => {

        }
      );
    // });

  }

  makeitbig(itemId: string, userId: string) {
    const self = this;
    self.userId = userId;
    if (itemId != null) {
      self.commonService.getLargeImage(itemId).subscribe(
        data => {
          self.showLargeImage = true;
          self.imageToDisplay.MainImage = data.MainImage;
          self.imageToDisplay.show = true;
          self.imageToDisplay.ThumbNailImages = data.ThumbNails;
          self.imageToDisplay.RouterPath = "/../viewotherprofile";
          self.imageToDisplay.UserId = self.userId;
        },
        error => {

        }
      );
    }
    else {
    }
  }

}
