import { Component, inject, Input, OnInit } from '@angular/core';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { Dropdown } from "src/app/dto/Dropdown";
import { EditPageMegaBucket } from "src/app/dto/EditPageMegaBucket";
import { SaveResponse } from "src/app/dto/SaveResponse";
import { BasicRegisterUserModel } from "src/app/dto/BasicRegisterUserModel";
import { GetEditProfileBasicDto } from "src/app/dto/GetEditProfileBasicDto";
import * as _ from 'lodash';
import { MessageService, ConfirmationService } from 'primeng/api';
import * as moment from 'moment';
import { LoaderService } from 'src/app/common/reusable/servicecenter/LoaderService';

@Component({
  selector: 'app-edit-basic-profile',
  templateUrl: './edit-basic-profile.component.html',
  styleUrls: ['./edit-basic-profile.component.css'],
  providers: [MessageService, ConfirmationService]
})
export class EditBasicProfileComponent implements OnInit {
  @Input() epd: EditPageMegaBucket = {} as EditPageMegaBucket;
  ui: GetEditProfileBasicDto;
  uic: EditPageMegaBucket;
  genderSelected: Dropdown;
  complexionSelected: Dropdown;
  religionDenominationSelected: Dropdown;
  maritalStatusSelected: Dropdown;
  physicalStatusSelected: Dropdown;
  educationCategorySelected: Dropdown;
  dioceseSelected: Dropdown;
  jobSelected: Dropdown;
  denominationSelected: Dropdown;
  dropdownSettings = {};
  srm: SaveResponse;
  userloggedIn: string;

  constructor(private commonService: Commonservice, private localStore: LocalStore, private messageService: MessageService,public loadingService: LoaderService) { }

  ngOnInit(): void {
    const self = this;
    self.ui = {} as GetEditProfileBasicDto;
    self.uic = {} as EditPageMegaBucket;
    self.uic = self.epd;
    self.userloggedIn = self.localStore.getCurrentLoggedInUserSync().UserId;
    self.getDataFromDb();
  }

  getDataFromDb() {
    const self = this;
    this.loadingService.startLoading();
    self.commonService.getEditProfileBasic(self.userloggedIn).subscribe({
      next: (response) => {
        self.ui = response;
        this.bindData();
      },
      error: (error) => {
        // Handle error
      },
      complete: () => {
        this.loadingService.stopLoading();
      }
    });
  }

  bindData() {
    const self = this;

    if (this.ui.candidates_dob) {
      // Define the expected input format
      const inputFormat = 'MM/DD/YYYY HH:mm:ss';

      // Parse the date using the specified format
      const parsedDate = moment(this.ui.candidates_dob, inputFormat);

      // Check if the parsed date is valid
      if (parsedDate.isValid()) {
        // Convert to UTC and then to a JavaScript Date object
        this.ui.candidates_dob = parsedDate.utc().toDate();
      } else {
        console.error('Invalid date format:', this.ui.candidates_dob);
      }
    }

    if (self.uic.Gender.find(x => x.Value.toString() == self.ui.candidate_gender) != undefined) {
      self.genderSelected = self.uic.Gender.find(x => x.Value.toString() == self.ui.candidate_gender);
    }
    else {
      if (self.uic.Gender != undefined) {
        self.genderSelected = _.first(self.uic.Gender).Value;
      }
      else {
        self.genderSelected = { Name: "Male", Value: 1 };
      }
    }

    if (self.uic.Complexion.find(x => x.Value.toString() == self.ui.candidate_complexion) != undefined) {
      self.complexionSelected = self.uic.Complexion.find(x => x.Value.toString() == self.ui.candidate_complexion);
    }
    else {
      if (self.uic.Complexion != undefined) {
        self.complexionSelected = _.first(self.uic.Complexion);
      }
      else {
        self.complexionSelected = {} as Dropdown;
      }
    }

    if (self.uic.MaritalStatus.find(x => x.Value.toString() == self.ui.candidate_Martial_Status) != undefined) {
      self.maritalStatusSelected = self.uic.MaritalStatus.find(x => x.Value.toString() == self.ui.candidate_Martial_Status);
    }
    else {
      if (self.uic.MaritalStatus != undefined) {
        self.maritalStatusSelected = _.first(self.uic.MaritalStatus).Value;
      }
    }


    if (self.uic.PhysicalStatus.find(x => x.Value.toString() == self.ui.candidate_Physical_Status) != undefined) {
      self.physicalStatusSelected = self.uic.PhysicalStatus.find(x => x.Value.toString() == self.ui.candidate_Physical_Status);
    }
    else {
      if (self.uic.PhysicalStatus != undefined) {
        self.physicalStatusSelected = _.first(self.uic.PhysicalStatus).Value;
      }
    }

    if (self.uic.EducationalCategory.find(x => x.Value.toString() == self.ui.candidate_Edu_Category) != undefined) {
      self.educationCategorySelected = self.uic.EducationalCategory.find(x => x.Value.toString() == self.ui.candidate_Edu_Category);
    }
    else {
      if (self.uic.EducationalCategory != undefined) {
        self.educationCategorySelected = _.first(self.uic.EducationalCategory).Value;
      }
    }

    if (self.uic.Diocese.find(x => x.Value.toString() == self.ui.candidate_Diocese) != undefined) {
      self.dioceseSelected = self.uic.Diocese.find(x => x.Value.toString() == self.ui.candidate_Diocese);
    }
    else {
      if (self.uic.Diocese != undefined) {
        self.dioceseSelected = _.first(self.uic.Diocese).Value;
      }
    }

    if (self.uic.Denomination.find(x => x.Value.toString() == self.ui.candidate_Denomination) != undefined) {
      self.denominationSelected = self.uic.Denomination.find(x => x.Value.toString() == self.ui.candidate_Denomination);
    }
    else {
      if (self.uic.Denomination != undefined) {
        self.denominationSelected = _.first(self.uic.Denomination).Value;
      }
    }
  }

  saveInformation() {
    const self = this;
    let basicRegisterUserModel: BasicRegisterUserModel = {} as BasicRegisterUserModel;
    basicRegisterUserModel.candidate_name = self.ui.candidate_name;
    let dob = self.ui.candidates_dob;
    if (_.isDate(dob)) {
      basicRegisterUserModel.candidates_dob = self.ui.candidates_dob;
    }
    else {
      basicRegisterUserModel.candidates_dob = new Date(self.ui.candidates_dob);
    }

    basicRegisterUserModel.candidate_Height = self.ui.candidate_Height;
    basicRegisterUserModel.candidate_Weight = self.ui.candidate_Weight;
    basicRegisterUserModel.candidate_gender = self.genderSelected.Value.toString();
    basicRegisterUserModel.candidate_complexion = self.complexionSelected.Value.toString();
    basicRegisterUserModel.candidate_Denomination = self.denominationSelected.Value.toString();
    basicRegisterUserModel.candidate_Dioccese = self.dioceseSelected.Value.toString();
    basicRegisterUserModel.candidate_Martial_Status = self.maritalStatusSelected.Value.toString();
    basicRegisterUserModel.candidate_Physical_Status = self.physicalStatusSelected.Value.toString();
    basicRegisterUserModel.candidate_Edu_Category = self.educationCategorySelected.Value.toString();
    basicRegisterUserModel.candidate_Education_Subject = self.ui.candidate_Education_Subject;
    basicRegisterUserModel.candidate_Username = self.userloggedIn;

    this.commonService.setBasicUserInfo(basicRegisterUserModel).subscribe(
      data => {
        self.srm = data;
        self.messageService.add({ severity: 'success', summary: 'Service Message', detail: self.srm.Message });
        self.getDataFromDb();
      },
      error => { }
    );
  }
}
