import { Injectable } from '@angular/core';
import { UserInformation } from '../../../db.service';

@Injectable()
export class LocalStore {
  obj: string = "";
  getCurrentLoggedInUserSync(): UserInformation {
    let userLoggedIn = JSON.parse(sessionStorage.getItem('userLoggedIn') || '{}') as UserInformation;
    return userLoggedIn;
  }

  async getCurrentLoggedInUser(): Promise<UserInformation> {
    let userLoggedIn = JSON.parse(sessionStorage.getItem('userLoggedIn') || '{}') as UserInformation;
    return userLoggedIn;
  }
}
