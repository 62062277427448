<page>
  <ng-containter *ngIf="showLoader">
    <loader></loader>
  </ng-containter>
  <div class="text-block">
    <div class="super-title">SENT TRASH</div>
    <div class="panel panel-default b-r-1">
      <div class="panel-header-container">
        <div class="panel-search">
          <input type="text" pInputText placeholder="SEARCH" [(ngModel)]="searchText"
            (ngModelChange)="globalSearch($event)" />
        </div>
      </div>
      <div class="panel-body blue-panel-addon l-p-0 r-p-0 b-p-0 t-p-0 remove-padding">
        <div class="control-section">
          <p-table [value]="sortedDataSend" responsiveLayout="scroll" [paginator]="true" [rows]="10"
            [showCurrentPageReport]="true" responsiveLayout="scroll" [rowsPerPageOptions]="[10, 25, 50]"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [(selection)]="selectedMessageItems">
            <ng-template pTemplate="header">
              <tr>
                <th style="width: 4rem">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th>PHOTO</th>
                <th>TO</th>
                <th>DATE</th>
                <th>STATUS</th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row>
              <tr>
                <td>
                  <p-tableCheckbox [value]="row"></p-tableCheckbox>
                </td>
                <td>
                  <div *ngIf="row.thumbNailImage">
                    <img [src]="row.thumbNailImage" class="thumbnail-image" (click)="
                          makeitbig(
                            row.candidateProfileImageTmb,
                            row.userFromID
                          )
                        " />
                  </div>
                </td>
                <td>
                  <div class="user-info">
                    <div class="ui-1">
                      {{ row.userToID }}
                    </div>
                    <div class="ui-2">{{ row.userTo }}</div>
                    <div class="ui-3">
                      {{ row.age }} Years/{{ row.height }}/{{
                      row.christaintype
                      }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="date-info">
                    <div class="di-1">
                      {{ row.userExpressedDate | date : "medium" }}
                    </div>
                    <div class="di-2"></div>
                  </div>
                </td>
                <td>
                  <div class="date-info">
                    <div class="di-1">{{ row.messageStatus }}</div>
                    <div class="di-2">
                      (Changed On: {{ row.messageStatusChangeDate }})
                    </div>
                  </div>
                </td>
                <td style="vertical-align: top">
                  <div class="action-box-2 text-right">
                    <div class="ibo-1">
                      <img pTooltip="DELETE PERMANENTLY" tooltipPosition="top"
                        src="../../../../assets/image/newicons/delete-10399.png" class="rc-ito-2" alt=""
                        (click)="openSendDeleteModal($event, row)" />
                    </div>
                    <div class="ibo-2">
                      <img pTooltip="RESTORE" tooltipPosition="top" src="../../../../assets/image/restore2.png"
                        class="rc-ito-5" alt="" (click)="openSendRestoreModal($event, row)" />
                    </div>
                    <div class="ibo-3"></div>
                    <div class="ibo-4"></div>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="grid-footer">
          <div class="flex py-1">
            <div class="col-1">
              <div *ngIf="sortedDataSend != undefined">
                <span class="label label-primary">Total Count {{ sortedDataSend.length }}</span>
              </div>
            </div>
            <div class="col-9"></div>
            <div class="col-2">
              <div class="text-right">
                <button pButton *ngIf="
                      sortedDataSend != undefined && sortedDataSend.length > 0
                    " (click)="openMultiDeleteModal()">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- ---------This section is for delete received ------ -->
  <ng-template [ngIf]="showExpressReceivedDelete">
    <div [ngClass]="[deletereceivedModel.show ? 'overlay-v' : 'overlay-h']">
      <div [ngClass]="[deletereceivedModel.show ? 'show-pop-v' : 'show-pop-h']">
        <div class="model-box">
          <div class="mb-1">
            <div class="mb-1-1"></div>
            <div class="mb-1-2" (click)="closeReceivedTrash()">X</div>
          </div>
          <div class="mb-1">
            <div>
              {{ deletereceivedModel.title }}
            </div>
          </div>
          <div class="mb-2">
            <div class="mb-2-1">
              <button type="button" (click)="deleteRecievedExpression(deletereceivedModel)" color="primary" pButton>
                YES
              </button>
            </div>
            <div class="mb-2-2">
              <button type="button" (click)="closeReceivedTrash()" color="primary" pButton>
                NO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- ---------This section is for restore received received ------ -->

  <!-- ---------This section is for restore received received ------ -->
  <ng-template [ngIf]="showExpressReceivedRestore">
    <div [ngClass]="[restoreReceivedModel.show ? 'overlay-v' : 'overlay-h']">
      <div [ngClass]="[restoreReceivedModel.show ? 'show-pop-v' : 'show-pop-h']">
        <div class="model-box">
          <div class="mb-1">
            <div class="mb-1-1"></div>
            <div class="mb-1-2" (click)="closeRestoreReceived()">X</div>
          </div>
          <div class="mb-1">
            <div>
              {{ restoreReceivedModel.title }}
            </div>
          </div>
          <div class="mb-2">
            <div class="mb-2-1">
              <button type="button" (click)="restoreExpressInterestReceived(restoreReceivedModel)" color="primary"
                pButton>
                YES
              </button>
            </div>
            <div class="mb-2-2">
              <button type="button" (click)="closeRestoreReceived()" color="primary" pButton>
                NO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- ---------This section is for restore received received ------ -->

  <!-- ---------This section is for delete send ------ -->
  <ng-template [ngIf]="showExpressSendDelete">
    <div [ngClass]="[deleteSendModel.show ? 'overlay-v' : 'overlay-h']">
      <div [ngClass]="[deleteSendModel.show ? 'show-pop-v' : 'show-pop-h']">
        <div class="model-box">
          <div class="mb-1">
            <div class="mb-1-1"></div>
            <div class="mb-1-2" (click)="closeDeleteSend()">X</div>
          </div>
          <div class="mb-1">
            <div>
              {{ deleteSendModel.title }}
            </div>
          </div>
          <div class="mb-2">
            <div class="mb-2-1">
              <button type="button" (click)="deleteExpressSend(deleteSendModel)" color="primary" pButton>
                YES
              </button>
            </div>
            <div class="mb-2-2">
              <button type="button" (click)="closeDeleteSend()" color="primary" pButton>
                NO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- ---------This section is for delete send ------ -->

  <!-- ---------This section is for restore send ------ -->
  <ng-template [ngIf]="showExpressSendRestore">
    <div [ngClass]="[restoreSendModel.show ? 'overlay-v' : 'overlay-h']">
      <div [ngClass]="[restoreSendModel.show ? 'show-pop-v' : 'show-pop-h']">
        <div class="model-box">
          <div class="mb-1">
            <div class="mb-1-1"></div>
            <div class="mb-1-2" (click)="closeRestoreReceived()">X</div>
          </div>
          <div class="mb-1">
            <div>
              {{ restoreSendModel.title }}
            </div>
          </div>
          <div class="mb-2">
            <div class="mb-2-1">
              <button type="button" (click)="restoreExpressInterestSent(restoreSendModel)" color="primary" pButton>
                YES
              </button>
            </div>
            <div class="mb-2-2">
              <button type="button" (click)="closeRestoreSend()" color="primary" pButton>
                NO
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <!-- ---------This section is for restore send ------ -->
  <p-toast position="bottom-right"></p-toast>
  <p-confirmPopup></p-confirmPopup>
  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>

</page>